import { computed, ref } from 'vue'
import type { Group } from '~/models/Group'
import type { BaseItem } from '~/models/Content/BaseItem'
import type { PlannedItem } from '~/models/Content/PlannedItem'
import type { GradeCode } from '~/models/Grade'
import type { SubjectCode } from '~/models/Subject'
import type { PrePersistWeek, Week } from '~/models/Planner/Week'
import type { YearPlan } from '~/models/Planner/YearPlan'
import { YearPlanRole } from '~/models/Planner/YearPlan'
import type { AddToWeek } from '~/models/AddToWeek'
import { defineStore, storeToRefs } from 'pinia'
import { usePlannerApi } from '~/api/plannerApi'
import useGroupsStore from '~/stores/groups'
import { useAuthStore } from '~/stores/auth'
import useSchoolYear from '~/composables/useSchoolYear'
import { useI18n } from '~/composables/useI18n'
import useArrayUtils from '~/utils/arrayUtils'
import { sleep, waitFor } from '~/utils/asyncUtils'
import useStringUtils from '~/utils/stringUtils'
import { useContentHelper } from '~/composables/useContentHelper'
import { MaxTimeoutMs } from '~/constants/api'
import usePlannerMapper from '~/composables/usePlannerMapper'
import useWeekStore from '~/stores/week'

const usePlannerStore = defineStore('planner', () => {
   /**
    * const { mapResourceItem } = usePlannerMapper() has to stay here, even though it is only used in addToWeek.
    * This is because mapResourceItem relies on useUrlBuilder, and useUrlBuilder needs route and router to work
    * properly. This means that all functions in the call path has to be called in the setup context of a
    * component in order for erverything to work properly.
    */
   const { mapResourceItem } = usePlannerMapper()
  const { t } = useI18n()
  const { isPlannedItem } = useContentHelper()
  const { currentSchoolYearCode } = useSchoolYear()
  const { intersect, truthy } = useArrayUtils()
  const { lastInPath } = useStringUtils()
  const {
    getYearPlans,
    deleteResource,
    putWeek,
    postToWeek,
    getYearPlan,
  } = usePlannerApi()
  const {
    isTeacher,
    userRelevantGrades,
    userSubjectsByGrades,
    activeUserGroup,
  } = storeToRefs(useAuthStore())
  const { selectedWeekNumber } = storeToRefs(useWeekStore())
  const groupsStore = useGroupsStore()
  const { showGroupDialog } = groupsStore

  const yearPlans = ref<YearPlan[]>([])
  const isLoadingYearPlans = ref(false)
  const isAddingToWeek = ref(false)
  const selectedGroup = ref<Group | null>(activeUserGroup.value ?? null)
  const selectedSubject = ref<SubjectCode>('')
  const selectedSchoolYear = ref<string>(currentSchoolYearCode)

  const userYearPlans = computed(() => yearPlans.value.filter((yearPlan: YearPlan) =>
    isTeacher.value ? intersect(yearPlan.roles, [YearPlanRole.Owner, YearPlanRole.Editor]).length > 0 : true,
  ))

  const relevantYearPlans = computed(() => userYearPlans.value.filter(isRelevantYearPlan))

  const relevantYearPlansForSelectedGroup = computed((): YearPlan[] => {
    if (isTeacher.value && !selectedGroup.value) return []
    return relevantYearPlans.value
      .filter((yearPlan) => (yearPlan.groups ?? []) // .groups is possibly null, handle with empty array
        .filter(truthy)
        .map((group: Group) => group.groupId)
        .includes(selectedGroup.value?.groupId || ''),
      )
  })

  async function addToWeek(resource: BaseItem|PlannedItem, weekNumber?: number): Promise<void> {
    weekNumber = weekNumber || selectedWeekNumber.value
    if (!weekNumber) {
      throw new Error(t('planner.createYearPlan.missingWeekNumber'))
    }

    if (!selectedGroup.value) {
      throw new Error(t('planner.createYearPlan.missingActiveGroup'))
    }

    let subject = selectedSubject.value
    let grade = selectedGroup.value.grade

    if (!subject || !grade) {
      [ subject, grade ] = await showGroupDialog({ ...selectedGroup.value })
    }

    if (isAddingToWeek.value) {
      await sleep(400)
      return addToWeek(resource, weekNumber)
    }

    isAddingToWeek.value = true

    const request: AddToWeek = {
      resource: mapResourceItem(resource),
      weekNumber,
      subject,
      grade,
      groupId: selectedGroup.value.groupId,
      schoolYear: currentSchoolYearCode
    }

    try {
      const { yearPlanIdentifier } = await postToWeek(request)
      const yearPlan = await getYearPlan(yearPlanIdentifier)

      const index = yearPlans.value
        .findIndex(({ identifier }) => identifier && identifier === yearPlan.identifier)

      index > -1
        ? yearPlans.value.splice(index, 1, yearPlan)
        : yearPlans.value.unshift(yearPlan)

      setTimeout(() => isAddingToWeek.value = false, 100)
    } finally {
      isAddingToWeek.value = false
    }
  }

  /**
   * Checks that a resource is the same as another. Matches by identifier first, locationId second.
   *
   * @param a
   * @param b
   */
  function isSameResource(a: BaseItem|PlannedItem, b: BaseItem|PlannedItem) {
    if (isPlannedItem(a) && isPlannedItem(b)) return a.identifier === b.identifier
    if (a.locationId && b.locationId) return a.locationId === b.locationId
    return false
  }

  /**
   * Find week with resource in selected year plan
   *
   * @param resource
   * @param weekNumber
   */
  function findWeekWithResourceInSelectedYearPlan(resource: BaseItem, weekNumber: number): Week|undefined {
    return relevantYearPlansForSelectedGroup.value
      .flatMap(({ plans }) => plans)
      .flatMap(({ weeks }) => weeks || [])
      .filter((w) => w.weekNumber === weekNumber && w.resources.some((r) => isSameResource(r, resource)))[0]
  }

  /**
   * Find weeks with resource in selected year plan
   *
   * @param resource
   * @param weekNumber
   */
  function findWeeksWithResourceInSelectedYearPlan(resource: BaseItem): Week[] {
    return relevantYearPlansForSelectedGroup.value
      .flatMap(({ plans }) => plans)
      .flatMap(({ weeks }) => weeks || [])
      .filter((w) => w.resources.some((r) => isSameResource(r, resource)))
  }

  /**
   * Check if resource is found in a selected year plan
   *
   * @param resource
   * @param weekNumber
   */
  function isResourceInSelectedYearPlanByWeek(resource: BaseItem, weekNumber: number) {
    return !!findWeekWithResourceInSelectedYearPlan(resource, weekNumber)
  }

  /**
   * Updates a week, both in API and store
   *
   * @param week
   */
  async function updateWeek(week: Partial<PrePersistWeek>) {
    const response = await putWeek(week)
    const plan = yearPlans.value.flatMap(({ plans }) => plans || [])
      .find(({ identifier }) => response.plan.identifier === identifier)

    if (!plan) {
      console.warn(`No plan found for week ${response.identifier}`)
      return
    }

    const index = plan.weeks.findIndex(({ identifier }) => identifier && identifier === response.identifier)

    if (index > -1) {
      plan.weeks.splice(index, 1, response)
    } else {
      plan.weeks.unshift(response)
    }
  }

  /**
   * Removes the resource relation from a week. Does not delete the resource
   *
   * @param week
   * @param resource
   */
  async function removeFromWeek(week: Week, resource: PlannedItem) {
    const persistWeek: Partial<PrePersistWeek> = {
      identifier: week.identifier,
      resources: week.resources.filter(({ identifier }) => identifier !== resource.identifier)
        .map((r) => r['@id'] ?? '')
    }

    return await updateWeek(persistWeek)
  }

  /**
   * Removes a resource. Will be removed in week it's in
   *
   * @param identifier
   */
  async function removeResource(identifier: string) {
    await deleteResource(identifier)

    userYearPlans.value.flatMap(({ plans }) => plans || [])
      .forEach((plan) => {
        plan.weeks.forEach((week) => {
          week.resources = week.resources.filter((r) => r.identifier !== identifier)
        })
      })
  }

  async function getYearPlansBySchoolYear(
    schoolYear: string,
    subjectCode?: SubjectCode,
    weekNumber?: number
  ): Promise<void> {
    if (isLoadingYearPlans.value) {
      return waitFor(() => !isLoadingYearPlans.value, MaxTimeoutMs)
    }
    isLoadingYearPlans.value = true
    try {
      const response  = await getYearPlans(schoolYear, subjectCode, weekNumber)
      response.forEach((yearPlan) => {
        const index = yearPlans.value
          .findIndex(({ identifier }) => identifier && identifier === yearPlan.identifier)
        if (index > -1) {
          yearPlans.value.splice(index, 1, yearPlan)
        } else {
          yearPlans.value.unshift(yearPlan)
        }
      })
    } catch (error) {
      console.error({ message: t('calendar.errors.loadYearPlans') })
      throw error
    } finally {
      setTimeout(() => isLoadingYearPlans.value = false, 100)
    }
  }

  /**
   * A relevant yearPlan intersects with your subjects
   * As a teacher it should also intersect with your grades
   *
   * @param yearPlan
   */
  function isRelevantYearPlan(yearPlan: YearPlan) {
    const relevantGrades = [
      activeUserGroup.value?.grade,
      ...userRelevantGrades.value,
    ]
    const relevantSubjects = [
      ...userSubjectsByGrades.value,
      ...activeUserGroup.value?.subjects ?? [],
    ]
    const grades = !relevantGrades.length
      || !isTeacher.value
      || intersect(relevantGrades, yearPlan.grades.map((g: string) => lastInPath(g) as GradeCode)).length > 0
    const subjects = !relevantSubjects.length
      || intersect(relevantSubjects, yearPlan.subjects.map((s: string) => lastInPath(s) as SubjectCode)).length > 0
    return grades && subjects
  }

  return {
    yearPlans,
    addToWeek,
    isResourceInSelectedYearPlanByWeek,
    relevantYearPlans,
    relevantYearPlansForSelectedGroup,
    isRelevantYearPlan,
    removeResource,
    removeFromWeek,
    isLoadingYearPlans,
    getYearPlansBySchoolYear,
    selectedGroup,
    selectedSubject,
    findWeeksWithResourceInSelectedYearPlan,
    selectedSchoolYear,
  }
})

export default usePlannerStore
