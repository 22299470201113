<script setup lang="ts">
import type { Product } from '~/models/Product'
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { KsButton, KsDialog, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { useAuthStore } from '~/stores/auth'
import { sortByNameGradeSubject } from '~/utils/productSorter'
import useProductStore from '~/stores/product'
import arrayUtils from '~/utils/arrayUtils'
import useSlugify from '~/composables/useSlugify'
import { colorMap } from '~/utils/colors'
import { ColorName } from '~/models/Content/Color'
import { isExcludedSubject, sortBySubjectIndex } from '~/utils/subjectSorter'

const { t } = useI18n()
const router = useRouter()
const { slugify } = useSlugify()
const { intersect } = arrayUtils()
const { products, addonProducts } = storeToRefs(useProductStore())
const { userRelevantGrades, selectedGradeTypeGrades } = storeToRefs(useAuthStore())
const colors = [
  ColorName.Yellow40,
  ColorName.Orange30,
  ColorName.CoralPop,
  ColorName.Violet10,
  ColorName.Purple20,
  ColorName.Blue20,
  ColorName.GreenPop,
  ColorName.Coral20,
  ColorName.White,
  ColorName.VioletPop,
  ColorName.SeaGreenPop,
]

type SupportProductName = 'Bokstøtte' | 'Lydstøtte'
const supportProductNames: SupportProductName[] = ['Bokstøtte', 'Lydstøtte']
const supportProducts = computed(() => supportProductNames.filter((supportProductName) => getSupportAddonProducts(supportProductName).length > 0))
const selectedSupportProduct = ref<SupportProductName | null>(null)

const relevantAddonProducts = computed(() => [...addonProducts.value]
  .filter((product) => isRelevantProduct(product) && supportProductNames.every((supportProductName) => !product.aunivers.name.toLowerCase().includes(supportProductName.toLowerCase())))
  .sort(sortByNameGradeSubject)
)

function getSupportAddonProducts(supportProductName: SupportProductName) {
  return [...addonProducts.value]
    .filter((product) => isRelevantProduct(product) && product.aunivers.name.toLowerCase().includes(supportProductName.toLowerCase()))
    .sort(sortByNameGradeSubject)
}

const subjects = computed(() => [
  ...new Set(products
    .value
    .filter(({ grades }) => grades.some((grade) => selectedGradeTypeGrades.value.includes(grade) && !isExcludedSubject(grade)))
    .map(({ subjects }) => subjects)
    .flat())
  ]
    .sort(sortBySubjectIndex)
)

const isRelevantProduct = (product: Product) => !product.grades.length
  || intersect(product.grades, userRelevantGrades.value).length > 0

const buildProductUrl = (product: Product) => {
  const { href } = router.resolve({
    name: 'product',
    params: {
      locationId: product.aunivers.locationId,
      slug: slugify(product.aunivers.name),
    },
  })
  return href
}

const getStyles = (index: number) => {
  const color = `rgb(var(${colorMap.get(colors[index % colors.length])?.var ?? '--au-white'}))`

  return {
    '--ks-secondary': color,
    '--ks-primarytext': color,
    'border': `2px solid ${color}`,
  }
}

</script>

<template>
  <ul
    v-if="subjects.length"
    :id="TeleportationTarget.SubjectRowButtons.replace('#', '')"
    class="flex flex-col gap-2 sm:flex-row sm:flex-wrap"
  >
    <li
      v-for="(subject, index) of subjects"
      :key="subject"
    >
      <RouterLink
        :to="{ name: 'subject', params: { subject: subject.toLowerCase() } }"
        class="inline-flex w-full rounded-xl transition focus-visible:ring"
      >
        <KsButton
          variant="primary"
          type="label"
          stretch
          center
          class="subject-button !text-2xl !font-bold"
          :style="getStyles(index)"
        >
          {{ t(`metadata.subjects.${subject}`) }}
        </KsButton>
      </RouterLink>
    </li>
    <li
      v-for="(addon, index) in relevantAddonProducts"
      :key="addon.ean"
    >
      <RouterLink
        :to="{ path: buildProductUrl(addon) }"
        class="inline-flex w-full rounded-xl transition focus-visible:ring"
      >
        <KsButton
          variant="primary"
          type="label"
          stretch
          center
          class="subject-button !text-2xl !font-bold"
          :style="getStyles(subjects.length + index)"
        >
          {{ addon.aunivers.name }}
        </KsButton>
      </RouterLink>
    </li>
    <li
      v-for="(supportProduct, index) of supportProducts"
      :key="supportProduct"
    >
      <KsButton
        variant="primary"
        stretch
        center
        class="subject-button relative !text-2xl !font-bold after:absolute after:bottom-0 after:right-0 after:box-content after:h-[--flip-size] after:w-[--flip-size] after:rounded-tl-[inherit] after:border-l-2 after:border-t-2 after:border-[#070707] after:bg-[--ks-secondary]"
        :style="{
          ...getStyles(subjects.length + relevantAddonProducts.length + index),
          '--flip-size': '1.5rem',
          'clip-path': 'polygon(0 0, 100% 0, 100% calc(100% - var(--flip-size)), calc(100% - var(--flip-size)) 100%, 0 100%)',
        }"
        @click="selectedSupportProduct = supportProduct"
      >
        {{ supportProduct }}
      </KsButton>

      <Teleport :to="TeleportationTarget.AppTop">
        <KsDialog
          :title="supportProduct"
          :open="selectedSupportProduct === supportProduct"
          @close="selectedSupportProduct = null"
        >
          <template #body>
            <ul
              v-if="subjects.length"
              :id="TeleportationTarget.SubjectRowButtons.replace('#', '')"
              class="grid flex-col gap-2"
            >
              <li
                v-for="(addon, index) in getSupportAddonProducts(supportProduct)"
                :key="addon.ean"
              >
                <RouterLink
                  :to="{ path: buildProductUrl(addon) }"
                  class="inline-flex w-full rounded-xl transition focus-visible:ring"
                >
                  <KsButton
                    variant="primary"
                    type="label"
                    stretch
                    center
                    class="subject-button !text-2xl !font-bold"
                    :style="getStyles(subjects.length + index)"
                  >
                    {{ addon.aunivers.name }}
                  </KsButton>
                </RouterLink>
              </li>
            </ul>
          </template>
        </KsDialog>
      </Teleport>
    </li>
  </ul>

  <KsSkeletonWrapper
    v-else
    class="flex flex-col gap-2 sm:flex-row sm:flex-wrap"
  >
    <KsSkeleton
      v-for="n in 9"
      :key="n"
      :width="`${n % 4 * 10 + 10}ch`"
      height="3rem"
    />
  </KsSkeletonWrapper>
</template>

<style scoped>
.subject-button {
  --ks-primary: #002422;
  padding: 1.2em 1em;
}
.subject-button:hover {
  --ks-primarytext: #002422!important;
}
</style>
