import type { BaseItem } from '~/models/Content/BaseItem'
import * as Sentry from '@sentry/browser'
import { ContentType } from '~/models/Content/ContentType'
import { useI18n } from './useI18n'

const { t } = useI18n()

export function useContentType() {

  const getName = (resource: BaseItem): string => {
    return getNameByContentTypeIdentifier(resource.contentTypeIdentifier)
  }

  const getNameByContentTypeIdentifier = (contentType: ContentType): string => {
    try {
      const translation: string = t(`contentTypes.${contentType}`)
      if (translation.includes('contentTypes')) throw new Error(`No translation found for content type "${contentType}"`)
      return translation
    } catch (err) {
      Sentry.captureException(err)
      return t('contentTypes.default')
    }
  }

  return {
    getName,
    getNameByContentTypeIdentifier,
  }
}
