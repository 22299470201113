<script setup lang="ts">
import { KsIcon } from '@aschehoug/kloss'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAppColor } from '~/composables/useAppColor'

const { themeColor } = useAppColor()
const route = useRoute()

const textColor = computed(() => {
  const name = themeColor.value?.name || ''
  const matches = /-(5|10|20)$/.test(name)
  return (matches || route.name === 'search') ? 'text-black' : 'text-white'
})
</script>

<template>
  <KsIcon
    id="logo-aunivers"
    :class="textColor"
  />
</template>
