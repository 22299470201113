<script setup lang="ts">
import { onUnmounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { ColorName } from '~/models/Content/Color'
import { useAppColor } from '~/composables/useAppColor'
import useAuthStore from '~/stores/auth'
import LowerPrimaryBanner from '~/components/home/LowerPrimaryBanner.vue'
import LowerPrimarySearch from '~/components/home/LowerPrimarySearch.vue'
import BlogPostSection from '~/components/blog/BlogPostSection.vue'

const appColor = useAppColor(ColorName.SeaGreen50)
const { isTeacher } = storeToRefs(useAuthStore())
const { t } = useI18n()

onUnmounted(() => appColor.clear())
</script>

<template>
  <div class="flex h-full flex-col">
    <LowerPrimaryBanner />
    <LowerPrimarySearch />
    <div class="grow bg-green-5">
      <BlogPostSection
        v-if="isTeacher"
        :limit="3"
      >
        <template #title>
          <h2
            class="font-extrabold fluid-text-xl"
            v-text="t('blog.title')"
          />
        </template>
      </BlogPostSection>
    </div>
  </div>
</template>
