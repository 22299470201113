<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsCallout } from '@aschehoug/kloss'
import { useMessagesStore } from '~/stores/messages'
import useProductStore from '~/stores/product'
import { useAuthStore } from '~/stores/auth'

const { t } = useI18n()
const { isTeacher } = storeToRefs(useAuthStore())
const { hasMessage, messages } = storeToRefs(useMessagesStore())
const { noLicensedProducts } = storeToRefs(useProductStore())

const webshopUrl = computed(() => <string>import.meta.env.VITE_WEBSHOP_TRIALURL)
</script>

<template>
  <section
    v-if="hasMessage || isTeacher && noLicensedProducts"
    class="mx-auto max-w-screen-au px-4 sm:px-8"
  >
    <KsCallout
      v-if="hasMessage"
      class="my-6"
      :title="messages?.messageTitle"
      variant="warning"
    >
      <div v-html="messages?.message" />
    </KsCallout>
    <KsCallout
      v-if="isTeacher && noLicensedProducts"
      class="my-6"
      :title="t('trial.title')"
      variant="info"
    >
      <div v-html="t('trial.message', { href: webshopUrl })" />
    </KsCallout>
  </section>
</template>
