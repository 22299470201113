<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { Component } from 'vue'
import { computed } from 'vue'
import { GradeType } from '~/models/GradeType'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import LowerSecondarySubject from '~/components/subject/gradetypes/LowerSecondarySubject.vue'
import LowerPrimarySubject from '~/components/subject/gradetypes/LowerPrimarySubject.vue'

defineProps<{ subjectCode: SubjectCode }>()

const { selectedGradeType } = storeToRefs(useAuthStore())

const gradeTypeComponentMap = new Map<GradeType, Component>([
  [GradeType.LowerPrimary, LowerPrimarySubject],
  [GradeType.LowerSecondary, LowerSecondarySubject]
])

const componentToRender = computed(() => {
  const gradeType = selectedGradeType.value
  return gradeType ? gradeTypeComponentMap.get(gradeType) || null : null
})
</script>

<template>
  <component
    :is="componentToRender"
    v-if="componentToRender"
    :subject-code="subjectCode"
  />
</template>
