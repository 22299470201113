<script setup lang="ts">
import type { GroupMember } from '~/models/Group'
import { KsDropdown, KsIcon } from '@aschehoug/kloss'
import useGroupsStore from '~/stores/groups'
import { computed, ref, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import { useI18n } from 'vue-i18n'
import useSchoolYear from '~/composables/useSchoolYear'
import usePlannerStore from '~/stores/planner'
import ChangeGroupButton from '~/components/group/ChangeGroupButton.vue'

const { t } = useI18n()
const { activeUserGroup, isTeacher } = storeToRefs(useAuthStore())
const { loadGroupMembers, findGroupMembers } = useGroupsStore()
const { selectedSchoolYear } = storeToRefs(usePlannerStore())
const { currentSchoolYearCode } = useSchoolYear()

const availableSchoolYears  = computed(() => [0, 1, 2].map((modifier) => {
  const split = currentSchoolYearCode.split('-').map((i) => Number(i))
  return `${split[0]-modifier}-${split[1]-modifier}`
}))

const groupMembers = ref<GroupMember[]>([])

watchEffect(() => activeUserGroup.value && loadGroupMembers(activeUserGroup.value)
  .then(() => groupMembers.value = activeUserGroup.value ? findGroupMembers(activeUserGroup.value) : []))
</script>

<template>
  <header
    class="
      relative h-[320px] flex-col justify-center overflow-hidden
      bg-gradient-to-tr from-seagreen-40 to-custom-green to-60% text-white
    "
  >
    <div
      class="
        relative z-10 mx-auto h-full w-full max-w-screen-au
        px-4 pb-10 pt-32 transition-all sm:px-8 sm:pb-20
      "
    >
      <KsDropdown
        v-model="selectedSchoolYear"
        :options="availableSchoolYears"
        :placeholder="t('filters.chooseSchoolYear')"
        :option-label="opt => opt"
        class="mb-4 z-20 overflow-visible"
        shape="rounded"
      />

      <h1
        class="relative z-10 font-semibold fluid-text-3xl"
        v-text="t('plan.content')"
      />
      <p
        v-if="activeUserGroup"
        class="relative z-10 mt-2 flex items-center gap-3 text-xl"
      >
        <b class="flex items-center gap-2">
          <KsIcon
            id="users"
            :scale="1.4"
          />
          {{ activeUserGroup.name }}
        </b>
        ({{ t('groups.membersCount', { count: groupMembers.length }) }})
        <ChangeGroupButton />
      </p>
      <ChangeGroupButton v-if="isTeacher && !activeUserGroup" />
      <img
        src="../../assets/images/calendar-illustration.png"
        aria-hidden="true"
        class="absolute -bottom-28 right-12 hidden w-[500px] transition-all md:block lg:-bottom-48 lg:right-24 lg:w-[650px]"
        alt=""
      >

    </div>
  </header>
</template>
