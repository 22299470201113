<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import { GradeType } from '~/models/GradeType'
import { setTitle } from '~/utils/dom'
import { useI18n } from 'vue-i18n'
import LowerPrimaryHome from '~/components/home/LowerPrimaryHome.vue'
import UpperSecondaryHome from '~/components/home/UpperSecondaryHome.vue'
import DefaultHome from '~/components/home/DefaultHome.vue'
import AdultHome from '~/components/home/AdultHome.vue'
import PrepHome from '~/components/home/PrepHome.vue'

const { t } = useI18n()
const { selectedGradeType } = storeToRefs(useAuthStore())
setTitle(t('home.title'))
</script>

<template>
  <LowerPrimaryHome v-if="selectedGradeType === GradeType.LowerPrimary" />
  <UpperSecondaryHome v-else-if="selectedGradeType === GradeType.UpperSecondary" />
  <AdultHome v-else-if="selectedGradeType === GradeType.AdultEducation" />
  <PrepHome v-else-if="selectedGradeType === GradeType.PrepEducation" />
  <DefaultHome v-else />
</template>
