import { computed, ref, watch } from 'vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { ColorName } from '~/models/Content/Color'
import { colorMap } from '~/utils/colors'

interface AppColorOptions {
  bgColor?: ColorName
  themeColor?: ColorName
}

const defaultAppColorOptions: Required<AppColorOptions> = {
  bgColor: ColorName.White,
  themeColor: ColorName.White,
}

const appColor = ref(defaultAppColorOptions)
const themeColorEl: HTMLMetaElement | null = document.head.querySelector('meta[name="theme-color"]')

const bgColor = computed(() => colorMap.get(appColor.value.bgColor))
const themeColor = computed(() => colorMap.get(appColor.value.themeColor))

function setAppColor(options: ColorName | AppColorOptions) {
  appColor.value = typeof options === 'string'
    ? { bgColor: options, themeColor: options }
    : { ...defaultAppColorOptions, ...options }
}

function clearAppColor() {
  appColor.value = defaultAppColorOptions
}

export function useAppColor(options?: ColorName | AppColorOptions) {
  const route = useRoute()

  if (route.matched.length > 0) {
    onBeforeRouteLeave(() => clearAppColor())
  }

  if (options) {
    setAppColor(options)
  }

  return {
    set: setAppColor,
    clear: clearAppColor,
    bgColor,
    themeColor,
  }
}

watch(appColor, ({ themeColor }) => {
  if (themeColorEl) {
    themeColorEl.content = colorMap.get(themeColor)?.rgb ?? 'white'
  }
}, { deep: true })
