import { SubjectCode } from '../models/Subject'

export const subjectsSorted: SubjectCode[] = [
  'NOR',
  'MAT',
  'ENG',
  'SAF',
  'KRL',
  'REL',
  'NAT',
  'FLE',
  'MUS',
  'KRO',
  'MHE',
  'KHV',
  'SAK',
  'TYS',
  'FRA',
  'SPA',
  'GEO',
  'GFG',
  'FYS',
  'KJE',
  'INF',
  'HIS',
  'SOS',
  'SOK',
  'SAG',
  'HEL',
  'PSY',
  'MED',
  'POL',
  'POM',
  'FSP',
  'TVE',
]

export const sortBySubjectIndex = (a: SubjectCode, b: SubjectCode): number => subjectsSorted.indexOf(a) - subjectsSorted.indexOf(b)

export const isExcludedSubject = (code: SubjectCode): boolean => [
  'MED',
  'FSP',
  'FLE',
  'POL',
  'POM',
].includes(code)
