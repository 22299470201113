<script setup lang="ts">
import { vResizeObserver } from '@vueuse/components'
import type { ResizeObserverEntry } from '@vueuse/core'

/**
 * This <script> block is purely for fallback in browsers that doesn't yet support scroll animations
 * It should be removed once https://developer.mozilla.org/en-US/docs/Web/CSS/animation-timeline has wider support
 */

const isSupported = CSS.supports('animation-timeline', 'scroll(self)')

function onScroll(event: Event) {
  const target = event.target as HTMLDivElement
  const { scrollHeight, scrollTop, clientHeight } = target
  const [animation] = target.getAnimations()

  if (animation) {
    animation.currentTime = scrollTop / (scrollHeight - clientHeight)
  }
}


function onResizeObserver(entries: ReadonlyArray<ResizeObserverEntry>) {
  for (const { target } of entries) {
    const { style } = target as HTMLDivElement

    if (target.scrollHeight === target.clientHeight) {
      style.animationFillMode = 'none'
      style.animationPlayState = 'running'
    }

    else {
      style.animationFillMode = 'both'
      style.animationPlayState = 'paused'
    }
  }
}
</script>

<template>
  <div
    v-if="isSupported"
    class="presentation-scroll"
  >
    <slot />
  </div>

  <div
    v-else
    v-resize-observer="onResizeObserver"
    class="presentation-scroll"
    @scroll.passive="onScroll"
  >
    <slot />
  </div>
</template>

<style scoped>
  .presentation-scroll {
    overflow: auto;
    scrollbar-width: auto;
    scrollbar-color: currentColor transparent;
    animation-name: shadow;
    animation-timeline: scroll(self);

    @supports not (animation-timeline: scroll(self)) {
      animation-duration: 1ms;
      animation-fill-mode: both;
      animation-play-state: paused;
    }

    @supports selector(::-webkit-scrollbar) and (not (scrollbar-color: auto)) {
      &::-webkit-scrollbar {
        width: 12px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: currentColor;
        border: 2px solid transparent;
        background-clip: content-box;
        border-radius: 1rem;
      }
    }
  }

  @keyframes shadow {
    0% {
      box-shadow:
        inset 0 0 3rem -3rem transparent,
        inset 0 -4rem 3rem -3rem rgb(0 0 0 / .25);
    }

    20% {
      box-shadow:
        inset 0 4rem 3rem -3rem rgb(0 0 0 / .25),
        inset 0 -4rem 3rem -3rem rgb(0 0 0 / .25);
    }

    80% {
      box-shadow:
        inset 0 4rem 3rem -3rem rgb(0 0 0 / .25),
        inset 0 -4rem 3rem -3rem rgb(0 0 0 / .25);
    }

    100% {
      box-shadow:
        inset 0 4rem 3rem -3rem rgb(0 0 0 / .25),
        inset 0 0 3rem -3rem transparent;
    }
  }
</style>
