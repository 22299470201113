<script setup lang="ts">
import type { ColorPair } from '~/models/Presentation/BasePage'
import type { Image } from '~/models/Content/Image'
import { computed } from 'vue'
import { KsIcon } from '@aschehoug/kloss'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'

const props = defineProps<{
  image: Image
  classes?: string | string[]
  colorPair?: ColorPair
}>()

const src = computed(() => props.image.src)
const altText = computed(() => props.image.altText)
const copyright = computed(() => props.image.copyright)
const caption = computed(() => props.image.caption)
</script>

<template>
  <div
    v-if="!!src"
    class="group relative h-full w-full"
  >
    <img
      :src="src"
      :alt="altText"
      :class="classes"
    >
    <div
      v-if="copyright || caption"
      class="absolute bottom-0 w-max max-w-prose space-y-1 bg-blue-50 px-6 py-3 text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100"
      :style="{ background: colorPair?.text, color: colorPair?.background }"
    >
      <RichTextRenderer
        v-if="caption"
        :text="caption"
      />
      <small
        v-if="copyright"
        class="flex items-center gap-2 text-sm"
      >
        <KsIcon
          id="copyright"
          :scale=".9"
        />
        <span v-text="copyright" />
      </small>
    </div>
  </div>
</template>

