<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { reverseMap } from '~/models/Language'
import { computed, defineAsyncComponent, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton, KsPopover } from '@aschehoug/kloss'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import usePlannerStore from '~/stores/planner'
import useDialogStore from '~/stores/dialog'
import { useContentHelper } from '~/composables/useContentHelper'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useError from '~/composables/useError'
import useGroupsStore from '~/stores/groups'
import CopyUrlButton from '~/components/cards/buttons/CopyUrlButton.vue'
import ConditionalTooltip from '~/components/utils/ConditionalTooltip.vue'

const props = withDefaults(defineProps<{
  resource: BaseItem
  buttonVariant?: 'primary' | 'secondary' | 'tertiary' | 'border'
  buttonClasses?: string
}>(),
{
  buttonVariant: 'secondary',
})

const toast = <Toast>inject('ksToast')
const { t } = useI18n()
const { isTeacher, hasOrganization } = storeToRefs(useAuthStore())
const { hasGroups } = storeToRefs(useGroupsStore())
const { isUploadedFile, isPackage, isFile } = useContentHelper()
const { buildResourceUrl, buildFileUrl } = useUrlBuilder()
const { addToWeek } = usePlannerStore()
const { showDialog } = useDialogStore()
const { getErrorMessage } = useError()

const showAddToWeekButton = computed(() => isTeacher.value && hasOrganization.value)
const showCopyUrlButton = computed(() => !isUploadedFile(props.resource) && !isPackage(props.resource))

const languageCodes = computed(() => props.resource.languageCodes.map((code) => (reverseMap[code])))

const addToAnotherWeek = async (resource: BaseItem) => {
  const weekNumber = await showDialog<number>(
    defineAsyncComponent(() => import('~/components/planner/WeekNumberDialog.vue')),
    { resource: resource },
  )

  try {
    await addToWeek(resource, weekNumber)
    toast.success(t('planner.addResource.success', { weekNumber }))
  } catch (error) {
    toast.error(getErrorMessage(error))
    throw error
  }
}
</script>
<template>
  <div>
    <KsPopover
      :title="t('resource')"
      direction="up left"
      :autoposition="false"
    >
      <template #toggle>
        <KsButton
          icon-left="ellipsis"
          shape="square"
          size="small"
          :variant="buttonVariant"
          :class="buttonClasses"
        />
      </template>
      <template #content>
        <slot name="menu">
          <KsButton
            v-if="isFile(props.resource)"
            v-for="language in languageCodes"
            :key="`file-${language}`"
            stretch
            icon-left="download"
            :href="buildFileUrl(props.resource, language)"
          >
            {{ t('downloadIn', { in: t(`metadata.language.${language}`).toLowerCase() }) }}
          </KsButton>
          <ConditionalTooltip
            v-if="showAddToWeekButton"
            :condition="!hasGroups"
            :text="t('planner.addToWeek.noGroups')"
          >
            <KsButton
              stretch
              :disabled="!hasGroups"
              icon-left="archive"
              @click.capture.stop="addToAnotherWeek(props.resource)"
            >
              {{ t('planner.addToWeek.title') }}
            </KsButton>
          </ConditionalTooltip>
          <CopyUrlButton
            v-if="showCopyUrlButton"
            :url="buildResourceUrl(props.resource)"
            icon-left="copy"
            stretch
          />
        </slot>
      </template>
    </KsPopover>
  </div>
</template>
