<script setup lang="ts">
import type { Presentation } from '~/models/Presentation/Presentation'
import { ContentType } from '~/models/Content/ContentType'
import { Subtree } from '~/models/Content/Subtree'
import { computed, onMounted, watch } from 'vue'
import PresentationPageRenderer from '~/components/ResourceEngine/PresentationPageRenderer.vue'
import router from '~/router'
import { useSearch } from '~/composables/useSearch'
import useSearchHelper from '~/composables/useSearchHelper'
import useSlugify from '~/composables/useSlugify'
import { setTitle } from '~/utils/dom'
import useContentMapper from '~/composables/useContentMapper'
import useEnvironment from '~/composables/useEnvironment'
import useLicenseControlStore from '~/stores/licenseControl'
import { KsSpinner } from '@aschehoug/kloss'

const { checkItemLicense } = useLicenseControlStore()

const props = withDefaults(defineProps<{
  locationId: number
  pageNumber?: number
}>(), {
  pageNumber: 1,
})

const { slugify } = useSlugify()
const { mapLocations, mapPresentation } = useContentMapper()
const { searchPath, emptyQuery, getLocationIdCriterion, getContentTypeCriterion, getSubtreeCriterion } = useSearchHelper()
const { isTest, isDev } = useEnvironment()

const {
  isLoading,
  results: presentations,
  fetchResults: fetchPresentations,
} = useSearch<Presentation[]>(searchPath, {
  transformData: (response) => mapLocations(response.View.Result).map(mapPresentation)
})

const presentationIsReady = computed(() => !isLoading.value && presentation.value)
const presentation = computed(() => {
  if (!presentations.value) return null
  return presentations.value[0]
})

const invalidPresentation = computed(() => {
  return !presentation.value || presentation.value.contentTypeIdentifier !== ContentType.Presentation
})

watch(presentation, () => checkItemLicense(presentation.value))

onMounted(async () => {
  try {
    await fetchPresentations(emptyQuery, {
      ...getLocationIdCriterion([props.locationId]),
      ...getContentTypeCriterion([ContentType.Presentation]),
      ...getSubtreeCriterion([Subtree.Content, Subtree.Media]),
    }, 1)
    if (invalidPresentation.value) throw new Error('Invalid presentation')
    setTitle(presentation.value?.title || '')
  } catch (error) {
    if (isTest() || isDev()) throw error
    await router.replace({
      name: 'notfound',
      query: { sti: router.currentRoute.value.fullPath }
    })
  }
})

const goToPage = (pageNumber: number, replace = false) => {
  const method = replace ? router.replace : router.push
  method({
    name: 'presentation-page',
    params: {
      locationId: presentation.value?.locationId,
      slug: slugify(presentation.value?.title || ''),
      pageNumber: pageNumber,
    },
  })
}
</script>
<template>
  <PresentationPageRenderer
    v-if="presentationIsReady && presentation"
    v-show="!isLoading"
    :page-content-ids="presentation?.pages.destinationContentIds || []"
    :page-number="props.pageNumber"
    :presentation="presentation"
    @update:page-number="goToPage"
    @replace:page-number="goToPage($event, true)"
  />
  <div
    v-else
    class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
  >
    <KsSpinner />
  </div>
</template>
