<script setup lang="ts">
import type { AggregationTermEntry } from '~/models/Content/Response'

import { KsButton } from '@aschehoug/kloss'

const props = defineProps<{
  entry: AggregationTermEntry
  name: string
}>()
</script>
<template>
  <KsButton
    variant="border"
    size="medium"
  >
    <slot />
    <div class="flex w-full justify-between">
      <span v-text="props.name" />
      <span
        class="font-normal text-gray-40"
        v-text="props.entry.count"
      />
    </div>
  </KsButton>
</template>
