<script setup lang="ts">
import type { Product } from '~/models/Product'
import { KsButton, KsCallout, KsDialog } from '@aschehoug/kloss'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { TeleportationTarget } from '~/models/TeleportationTarget'

defineProps<{
  products: Product[]
}>()

const { t } = useI18n()

const open = ref(false)
</script>

<template>
  <KsButton
    variant="border"
    shape="rounded"
    size="small"
    icon-left="info-circle"
    @click="open = true"
  >
    {{ t('search.settings.mySettings') }}
  </KsButton>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :open="open"
      :title="t('search.settings.mySettings')"
      size="medium"
      @close="open = false"
    >
      <template #body>
        <div class="space-y-6 text-black">
          <div>
            <p
              class="mb-2 text-lg font-medium"
              v-text="t('search.settings.results')"
            />
            <ul class="mb-4 flex flex-wrap gap-2">
              <li
                v-for="product in products"
                :key="product.name"
                class="rounded border border-gray-20 px-3 py-1"
                v-text="product.name"
              />
            </ul>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end">
          <KsButton
            shape="rounded"
            variant="primary"
            @click="open = false"
          >
            {{ t('close') }}
          </KsButton>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>
