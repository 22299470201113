import type { BaseItem } from '~/models/Content/BaseItem'
import { defineStore } from 'pinia'
import { useRoute } from 'vue-router'
import { ref, computed, watch } from 'vue'
import useLicenseControlApi from '~/api/licenseControlApi'

const useLicenseControlStore = defineStore('licenseControl', () => {
  const licenseControlEnabled: boolean = (import.meta.env.VITE_LICENSE_CONTROL_ENABLED.toLowerCase() === 'true')

  const { checkLicenseByPathString } = useLicenseControlApi()
  const route = useRoute()

  const accessRestricted = ref<boolean>(false)
  const routeLocation = ref<number | undefined>(undefined)

  const hasAccessRestricted = computed(() => accessRestricted.value)

  /**
   * Disable overlay on route change, e.g. if browser-back is pressed
   */
  watch(route, (newRoute): void => {
    const locationId: number | undefined = newRoute.params.locationId ? Number(newRoute.params.locationId) : undefined
    if (routeLocation.value !== locationId) resetLicenseControlStore()
  })

  /**
   * Triggers the license control check.
   * Activates the overlay and washes the page if the user is not allowed to access the content.
   * @param item The item to check the license for. Returns early if noe item is provided to allow
   * for less checking on the providing side.
   */
  const checkItemLicense = async (item?: BaseItem | null): Promise<void> => {
    if (!licenseControlEnabled) return
    if (!item) return
    resetLicenseControlStore()
    routeLocation.value = item.locationId

    const isAllowed = await checkLicenseByPathString(item.pathString)
    if (!isAllowed) accessRestricted.value = true
  }

  const resetLicenseControlStore = () => {
    accessRestricted.value = false
    routeLocation.value = undefined
  }

  return {
    checkItemLicense,
    hasAccessRestricted,
    resetLicenseControlStore,
  }
})

export default useLicenseControlStore
