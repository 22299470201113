<script setup lang="ts">
import type { Product } from '~/models/Product'
import { computed } from 'vue'
import { KsButton, KsDialog } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { TeleportationTarget } from '~/models/TeleportationTarget'

const props = defineProps<{
  open: boolean
  product: Product | undefined
}>()

const { t } = useI18n()

const webshopUrl = computed(() => props.product?.aunivers.webshopUrl
  ? props.product?.aunivers.webshopUrl
  : import.meta.env.VITE_WEBSHOP_TRIALURL)

const description = computed(() => props.product
  ? t('search.lockedContent.relatedProduct', { product: props.product.name })
  : t('search.lockedContent.unknownProduct'))

const emit = defineEmits(['closeDialog'])
</script>

<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :open="open"
      :title="t('search.lockedContent.noAccess')"
      @close="emit('closeDialog')"
    >
      <template #body>
        <p
          class="mb-1"
          v-html="description"
        />
        <p v-text="t('search.lockedContent.find')" />
      </template>
      <template #footer>
        <div class="flex justify-end gap-3">
          <KsButton
            shape="rounded"
            @click="emit('closeDialog')"
          >
            {{ t('close') }}
          </KsButton>
          <KsButton
            shape="rounded"
            variant="primary"
            icon-right="arrow-up-right-square"
            :href="webshopUrl"
            target="_blank"
            @click="emit('closeDialog')"
          >
            {{ t('search.lockedContent.goToWebshop') }}
          </KsButton>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>
