<script lang="ts" setup>
import type { SectionItem } from '~/models/Section'
import { ContentType } from '~/models/Content/ContentType'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Expanded from '~/components/ResourceEngine/Components/Expanded.vue'
import Standalone from '~/components/ResourceEngine/Components/Standalone.vue'
import Box from '~/components/ResourceEngine/Components/Box.vue'
import DefaultComponent from '~/components/ResourceEngine/Components/DefaultComponent.vue'
import ResourceRenderer from '~/components/ResourceEngine/ResourceRenderer.vue'
import SubjectRowButton from './Components/SubjectRowButton.vue'
import useProductStore from '~/stores/product'

const { t } = useI18n()
const { availableEans } = storeToRefs(useProductStore())

interface ResourceRendererProps {
  items: SectionItem[]
}

const props = defineProps<ResourceRendererProps>()

const componentMap: Record<string, any> = {
  [ContentType.SectionExpanded]: Expanded,
  [ContentType.SectionStandalone]: Standalone,
  [ContentType.SectionBox]: Box,
  [ContentType.SectionSubjectRowButton]: SubjectRowButton,
}

const getComponent = (section: SectionItem) => {
  return componentMap[section.props.contentTypeIdentifier] ?? DefaultComponent
}

const router = useRouter()
const routeName = computed(() => router.currentRoute.value.name)
const isAnchor = computed(() => ['subject'].includes(<string>routeName.value))

const licensedSections = computed(() =>
    props.items.filter((item) => item.ean.length === 0 || availableEans.value.includes(item.ean))
)
</script>

<template>
  <h2
    v-if="licensedSections.length > 0"
    class="mx-auto mb-5 max-w-screen-au px-4 text-xl font-bold text-gray-50 sm:px-8"
    v-text="t('recommendedContent')"
  />
  <div class="flex flex-col gap-12">
    <component
      :is="getComponent(section)"
      v-for="section in licensedSections"
      :key="section.props.locationId"
      :props="section.props"
      :resources="section.resources"
      :featured="section.featured"
    >
      <template #resource="{ resourceItem }">
        <ResourceRenderer
          :items="[ resourceItem ]"
          :is-anchor="isAnchor"
        />
      </template>
    </component>
  </div>
</template>
