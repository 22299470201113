import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import useLicenseApi from '~/api/licenseApi'
import { LicenseDetails } from '~/models/License'

const useLicenseStore = defineStore('license', () => {

  const licenses = ref<LicenseDetails[]>([])
  const isLoadingLicenses = ref(false)
  const hasLoadedLicenses = ref(false)

  const loadLicenses = async (): Promise<void> => {
    const { getLicenseDetails } = useLicenseApi()
    isLoadingLicenses.value = true
    licenses.value = await getLicenseDetails()
      .finally(() => {
        isLoadingLicenses.value = false
        hasLoadedLicenses.value = true
      })
  }

  const allLicenses = computed(() => licenses.value)

  const hasLicenses = computed(() => allLicenses.value.length > 0)

  const $reset = (): void => {
    licenses.value = []
    isLoadingLicenses.value = false
    hasLoadedLicenses.value = false
  }

  return {
    licenses,
    loadLicenses,
    isLoadingLicenses,
    hasLoadedLicenses,
    allLicenses,
    hasLicenses,
    $reset,
  }
})

export default useLicenseStore
