<script setup lang="ts">
import { KsIcon } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
<template>
  <div class="flex w-full justify-center py-8">
    <a
      href="/"
      class="inline-flex focus-visible:ring"
      :aria-label="t('home')"
    >
      <KsIcon
        id="logo-aunivers"
        :scale="1.8"
        class="text-seagreen-50"
      />
    </a>
  </div>
</template>
