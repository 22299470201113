export interface PendoVisitor {
  id: string
  role: string
  organizationName: string
  organizationNumber: string
  loggedIn: boolean
  locale: string
  grades: string[]
  readOnlyGrades: string[]
  subjects: string[]
  trialOnly?: boolean // Should only be set when we actually know the value, not initialized as e.g. false
}

export interface PendoAccount {
  id: string
  organizationName: string
  organizationNumber: string
  trialOnly?: boolean
}

export interface PendoUser {
  visitor: PendoVisitor
  account: PendoAccount
  events?: PendoEvents
}

export interface PendoEvents {
  ready: () => void
}

export interface PendoSearchData {
  text: string
  subjects: string
  grades: string
  labels: string
  results: number
}

export interface PendoLoginButtonTrack {
  type: string
  app: string
}

export type PendoTrackData = PendoSearchData | PendoLoginButtonTrack

export enum PendoTrackName {
  SearchPage = 'SEARCH_PAGE',
  LoginButton = 'LOGIN_BUTTON',
  SubjectSearch = 'SUBJECT_SEARCH',
}

export enum PendoEventName {
  Ready = 'pendo:ready',
}

export namespace PendoFeature {
  export enum Navigation {
    Home = 'Navigasjon.Hjem',
    Search = 'Navigasjon.Søk',
    Planner = 'Navigasjon.Planlegger',
    Settings = 'Navigasjon.Innstillinger',
    StudentActivity = 'Navigasjon.Elevaktivitet',
    Burger = 'Navigasjon.Burger',
  }
  export enum Presentation {
    Next = 'Presentasjon.NesteSide',
    Previous = 'Presentasjon.ForrigeSide',
    Overview = 'Presentasjon.Innholdsoversikt',
    Exit = 'Presentasjon.Lukk',
    Fullscreen = 'Presentasjon.Fullskjerm',
  }
  export enum NewSubjectView {
    PopularSearch = 'Fagside.Søkeord',
  }
  export enum TeacherContent {
    Subject = 'Lærerinnhold.Fagside',
    Header = 'Lærerinnhold.Temaside',
    Back = 'Lærerinnhold.Tilbake',
  }
  export enum Colophon {
    Subject = 'Kolofon.Fagside',
    Back = 'Kolofon.Tilbake',
  }
  export enum Card {
    CopyUrl = 'Kort.KopierUrl',
  }
}
