import { externalClient } from '~/api/client/externalClient'
import axios from 'axios'

export default function useLicenseControlApi() {

  /**
   * Should return false on 403, otherwise _always_ true.
   * @param {string} path - The path to check. E.g. /1/2/160/1228/1276/3293/105114/284407/
   */
  const checkLicenseByPathString = async (path: string): Promise<boolean> => {
    try {
      await externalClient.get('/frontend/licenses/path', { params: { pathstring: path } })
      return true
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 403) return false
    }

    return true
  }

  return {
    checkLicenseByPathString
  }
}
