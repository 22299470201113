<script setup lang="ts">
import type { PageRichText } from '~/models/Presentation/BasePage'
import { computed } from 'vue'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'

const props = defineProps<{ page: PageRichText }>()

const tableBorderColor = computed(() => `rgb(var(${props.page.colorPair.text.var}) / .38)`)
const hoverStyle = computed(() => `rgb(var(${props.page.colorPair.text.var}) / .15)`)

/* Tailwind classes cant be computed or applied with v-bind().
   To render font and weight we need to return regular CSS :( */

const fontFamily = computed(() => {
  switch (props.page.fontFamily) {
    case 'font-inter':
      return '"Inter", sans-serif'
    case 'font-calistoga':
      return '"Calistoga", serif'
    case 'font-playpen':
      return '"Playpen Sans", cursive'
    case 'font-tiempos':
      return '"Tiempos", serif'
    default:
      return '"Inter", sans-serif'
  }
})
const fontWeight = computed(() => {
  switch (props.page.fontWeight) {
    case 'font-regular':
      return '400'
    case 'font-medium':
      return '500'
    case 'font-extrabold':
      return '800'
    default:
      return '500'
  }
})

const hasHeader = computed(() => props.page.header || props.page.heading)
const hasSuperLargeHeading = computed(() => props.page.textSizes.heading === 'fluid-text-10xl')
</script>

<template>
  <article
    class="au-presentation-richtext"
    :class="[
      page.textSizes.paragraph,
      hasHeader && !hasSuperLargeHeading ? 'mt-[1.2em]' : 'mt-0',
      {'mt-[.8em]': hasHeader && hasSuperLargeHeading},
      {'prose-headings:my-12 prose-p:my-10 prose-blockquote:my-10 prose-ul:my-10 prose-table:my-10': hasSuperLargeHeading},
    ]"
  >
    <RichTextRenderer :text="page.body" />
  </article>
</template>

<style scoped>
article {
  color: v-bind('page.colorPair.text.rgb');
  --tw-prose-bullets: v-bind('page.colorPair.text.rgb');
  --tw-prose-counters: v-bind('page.colorPair.text.rgb');
  --tw-prose-bold: v-bind('page.colorPair.text.rgb');
  --tw-prose-td-borders: v-bind(tableBorderColor);
  --tw-prose-th-borders: v-bind('page.colorPair.text.rgb');
  --tw-prose-headings: v-bind('page.colorPair.text.rgb');
  --tw-prose-links: v-bind('page.colorPair.text.rgb');
  --tw-prose-pre-bg: v-bind('page.colorPair.text.rgb');
  --tw-prose-pre-code: v-bind('page.colorPair.background.rgb');
  --tw-prose-quote-borders: v-bind('page.colorPair.text.rgb');
  --tw-prose-quotes: v-bind('page.colorPair.text.rgb');
}
:deep([class^="cms-"]:first-child) {
  margin-top: 0;
}
:deep(.cms-pre) {
  margin-bottom: 0;
}
:deep(.cms-a:hover) {
  background: v-bind(hoverStyle);
}
:deep(.cms-p code) {
  color: v-bind('page.colorPair.text.rgb');
}
:deep(.cms-h) {
  font-family: v-bind(fontFamily);
  font-weight: v-bind(fontWeight);
}
:deep(.cms-table) {
  margin: 1.5em 0;
}
:deep(.audio-control) {
  display: contents;
}
:deep(.align-left) {
  display: flex;
  justify-content: flex-start;
}
:deep(.align-center) {
  display: flex;
  justify-content: center;
}
:deep(.align-right) {
  display: flex;
  justify-content: flex-end;
}
</style>
