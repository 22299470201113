import type { ResourceItem } from '~/models/Resource'
import type { Location } from '~/models/Content/Response'
import type { BaseField, FieldTypeIdentifier, FieldValue } from '~/models/Content/BaseField'
import type { ContentResource } from '~/models/Content/ContentResource'
import type { GradeCode } from '~/models/Grade'
import { Section } from '~/models/Content/Section'
import useContentMapper from '~/composables/useContentMapper'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'

export default () => {
  const { mapBaseFields, getFieldValue } = useContentMapper()
  const { userPreferredLanguageCode } = storeToRefs(useAuthStore())

  const createField = (
    fieldDefinitionIdentifier: string,
    fieldTypeIdentifier: FieldTypeIdentifier,
    value: FieldValue
  ): BaseField<FieldValue> => ({
    fieldDefinitionIdentifier,
    fieldTypeIdentifier,
    fieldValue: value,
    id: 0,
    languageCode: userPreferredLanguageCode.value,
  })

  const mapResourceToContent = (resource: ResourceItem): ContentResource =>
    mapContentResource(mapResourceToLocation(resource))

  // Maps a legacy Resource model from aunivers-ibexa.
  // Using this on new features is probably a mistake.
  const mapContentResource = (location: Location): ContentResource => ({
    ...mapBaseFields(location),
    identifier: getFieldValue<string>(location, 'identifier', ''),
    grades: getFieldValue<GradeCode[]>(location, 'grades') ?? [],
    subjects: getFieldValue<string[]>(location, 'subjects') ?? [],
    description: getFieldValue<string>(location, 'description') ?? '',
    thumbnail: getFieldValue<string>(location, 'image') ?? '',
    thumbnailAlt: getFieldValue<string>(location, 'image_alt_text') ?? '',
    image: getFieldValue<string>(location, 'image') ?? '',
    colors: getFieldValue<string[]>(location, 'colors') ?? [],
    imageAltText: getFieldValue<string>(location, 'image_alt_text') ?? '',
    imageIsFallback: getFieldValue<boolean>(location, 'image_is_fallback') ?? false,
    url: getFieldValue<string>(location, 'url') ?? '',
    alignText: getFieldValue<boolean>(location, 'align_text') ?? false,
    displayTitle: getFieldValue<string>(location, 'display_title') ?? '',
    removeSubject: getFieldValue<boolean>(location, 'remove_subject') ?? false,
    link: getFieldValue<string>(location, 'link'),
    linkText: getFieldValue<string>(location, 'link_text'),
    hrefText: getFieldValue<string>(location, 'href_text'),
    parentLocationId: null,
    activity: getFieldValue<string>(location, 'activity'),
  })

  const mapResourceToLocation = (resource: ResourceItem, yearPlanId: string|undefined = undefined): Location => {
    return {
      id: resource.locationId || 0,
      childCount: 0,
      pathString: resource?.pathString || '',
      sortField: 'priority',
      sortOrder: 'asc',
      UrlAliases: {
        _href: ''
      },
      ContentInfo: {
        _href: '',
        Content: {
          _id: resource?.contentId || 0,
          _remoteId: `remote-${resource.contentId}`,
          Name: resource.title,
          TranslatedName: resource.title,
          publishedDate: resource.createdAt,
          CurrentVersion: {
            Version: {
              VersionInfo: {
                id: 0,
                versionNo: 1,
                status: 'PUBLISHED',
                modificationDate: resource.updatedAt,
                creationDate: resource.createdAt,
                initialLanguageCode: userPreferredLanguageCode.value,
                languageCodes: userPreferredLanguageCode.value,
              },
              Fields: {
                field: [
                  createField('identifier', 'ezstring', resource.identifier),
                  createField('@id', 'ezstring', resource['@id'] ?? ''),
                  createField('title', 'ezstring', resource.title),
                  createField('url', 'ezstring', resource.url),
                  createField('image', 'ezstring', resource.imageSrc),
                  createField('image_alt_text', 'ezstring', resource.imageAltText),
                  createField('year_plan_id', 'ezstring', yearPlanId || ''),
                  createField('subjects', 'eztags', resource.subjects),
                  createField('grades', 'eztags', resource.grades),
                  createField('description', 'ezrichtext', resource.description),
                  createField('image_is_fallback', 'ezboolean', resource.imageIsFallback),
                  createField('colors', 'eztags', resource.colors),
                  createField('short_title', 'ezstring', resource.shortTitle),
                  createField('align_text', 'ezboolean', (resource.fields || []).find(({ fieldDefIdentifier }) => fieldDefIdentifier === 'align_text')?.value?.bool || false),
                  createField('display_title', 'ezstring', (resource.fields || []).find(({ fieldDefIdentifier }) => fieldDefIdentifier === 'display_title')?.value?.text || ''),
                  createField('remove_subject', 'ezstring', (resource.fields || []).find(({ fieldDefIdentifier }) => fieldDefIdentifier === 'remove_subject')?.value?.bool || false),
                  createField('link', 'ezstring', (resource.fields || []).find(({ fieldDefIdentifier }) => fieldDefIdentifier === 'link_external')?.value?.link || ''),
                  createField('link_text', 'ezstring', (resource.fields || []).find(({ fieldDefIdentifier }) => fieldDefIdentifier === 'link_external')?.value?.text || ''),
                  createField('href_text', 'ezstring', (resource.fields || []).find(({ fieldDefIdentifier }) => fieldDefIdentifier === 'href_text')?.value?.text || ''),
                  createField('activity', 'ezstring', resource.activity || ''),
                ]
              }
            }
          },
          ContentType: {
            _identifier: resource.contentTypeIdentifier,
          },
          MainLocation: {
            _href: `/${resource.mainLocationId}`
          },
          Section: {
            _href: `/${Section.Standard}`
          }
        },
      }
    }
  }

  return {
    mapResourceToContent,
    mapResourceToLocation,
    mapContentResource,
  }
}
