import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default () => {
  const route = useRoute()

  const banner = computed(() => route.meta.banner)

  return {
    banner,
  }
}
