import type { User } from '~/models/User/User'
import type { Group } from '~/models/Group'
import type { SessionUserInfo } from '~/models/User/SessionUserInfo'
import type { GradeCode } from '~/models/Grade'
import type { SubjectCode } from '~/models/Subject'
import type { Organization } from '~/models/Organization'
import type { ActiveUserFilter } from '~/models/User/ActiveUserFilter'
import type { LanguageCode } from '~/models/Content/BaseField'
import { languageMap } from '~/models/Language'
import { GradeType } from '~/models/GradeType'
import { UserRole } from '~/models/User/UserRole'
import { PreferredLanguage } from '~/models/User/PreferredLanguage'
import { computed, ref, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import {
  sortByGradeIndex,
  upperSecondaryAdultAndPrepGrades,
  findRelevantGradeType,
  findGradesByGradeType,
  gradesSorted
} from '~/utils/gradeSorter'
import { findGradesBySubject, findSubjectsByGrade } from '~/utils/subjectMetadata'
import { isExcludedSubject, sortBySubjectIndex } from '~/utils/subjectSorter'
import useUserApi from '~/api/userApi'
import arrayUtils from '~/utils/arrayUtils'
import usePendo from '~/composables/usePendo'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'

export const useAuthStore = defineStore('auth', () => {
  const { getUser, postUser, postUserOrganization } = useUserApi()
  const { hasOnlyTrialProducts } = storeToRefs(useProductStore())
  const { intersect, unique } = arrayUtils()
  const { initPendo, updatePendo } = usePendo()

  const user = ref<User | undefined>(undefined)
  const isLoading = ref(false)

  const username = computed(() => user.value?.username || '')
  const isAuthenticated = computed(() => !!user.value?.username)
  const hasGrades = computed(() => (user.value?.grades || []).filter((g) => gradesSorted.includes(g)).length > 0)
  const hasSubjects = computed(() => !!user.value?.subjects.length)
  const hasPreferredLanguage = computed(() => !!user.value?.preferredLanguage)
  const userGradesWithoutReadOnly = computed((): GradeCode[] => user.value?.grades.filter((grade) => !userReadOnlyGrades.value.includes(grade)) || [])
  const userGrades = computed((): GradeCode[] => {
    if (intersect(userReadOnlyGrades.value, selectedGradeTypeGrades.value).length === 0) return userGradesWithoutReadOnly.value
    return [...(user.value?.grades || [])].sort(sortByGradeIndex)
  })
  const userReadOnlyGrades = computed((): GradeCode[] => user.value?.userData?.readOnlyGrades || [])
  const userSubjects = computed(() => (user.value?.subjects || []).sort(sortBySubjectIndex).filter(unique<SubjectCode>))
  const userOrganization = computed(() => user.value?.organization)
  const userOrganizations = computed((): Organization[] => user.value?.userData?.schoolOrganizations || [])
  const needsOrganization = computed(() => !hasOrganization.value && hasMultipleOrganizations.value)
  const hasMultipleOrganizations = computed(() => userOrganizations.value.length > 1)
  const userPreferredLanguage = computed(() => user.value?.preferredLanguage || PreferredLanguage.Bokmal)
  const userPreferredGrade = computed(() => user.value?.preferredGrade || null)
  const userRole = computed(() => user.value?.userData.role || UserRole.Anonymous)
  const isTeacher = computed(() => user.value?.userData.role === UserRole.Teacher)
  const isStudent = computed(() => user.value?.userData.role === UserRole.Student)
  const hasOrganization = computed(() => !!user.value?.organization?.number)
  const activeUserFilters = computed((): ActiveUserFilter[] => user.value?.userData.activeUserFilters || [])
  const userId = computed(() => username.value)
  const isUpperSecondaryAdultOrPrepUser = computed(() => intersect(upperSecondaryAdultAndPrepGrades, userGrades.value).length > 0)

  const userPreferredLanguageCode = computed(() => (languageMap[userPreferredLanguage.value] as LanguageCode))

  const activeUserGroups = computed((): Group[] =>
    activeUserFilters.value
      .filter(({ group }) => !!group)
      .map(({ group }) => ({
        // @ts-ignore Temporary fix for groups not having subjects in production APIs (yet). Remove when fixed.
        subjects: [],
        ...group,
      }))
      .filter(({ ownerOrganizationNumber: orgNumber }) => orgNumber && orgNumber === userOrganization.value?.number)
  )

  const activeUserGroup = computed(() => {
    if (!activeUserGroups.value[0]) return null
    return activeUserGroups.value[0]
  })

  function setUserActiveGroup(group?: Group) {
    const sessionInfo = { ...sessionUserInfo.value }
    sessionInfo.activeUserFilters = group ? [{
      group: group,
      organizationNumber: String(userOrganization.value?.number),
      fromDate: undefined,
      toDate: undefined,
      grade: undefined,
      student: undefined,
    }] : []
    return updateUser(sessionInfo)
  }

  /**
   * Finds subjects that matches users' grades
   */
  const userSubjectsByGrades = computed((): SubjectCode[] => {
    return userGrades.value
    .flatMap(findSubjectsByGrade)
    .filter(unique)
    .filter((subjectCode) => !isExcludedSubject(subjectCode))
    .sort(sortBySubjectIndex)
  })

  /**
   * Finds grades that matchers users' subjects
   */
  const userGradesBySubjects = computed((): GradeCode[] => {
    return userSubjectsByGrades.value
    .flatMap(findGradesBySubject)
    .filter(unique)
    .sort(sortByGradeIndex)
  })

  /**
   * Finds grades that matchers users' grade type
   */
  const userGradesByGradeType = computed((): GradeCode[] => {
    return selectedGradeType.value
      ? findGradesByGradeType(selectedGradeType.value)
      : []
  })

  /**
   * Finds relevant grades which is either
   *
   *  1. Grades in their settings
   *  2. Grades matching subjects
   *  3. No grades
   */
  const userRelevantGrades = computed((): GradeCode[] => {
    if (userGrades.value.length) return userGrades.value
    if (userGradesBySubjects.value.length) return userGradesBySubjects.value
    return []
  })

  /**
   * The selected grade type is the grade type that is most relevant to the user. For most students
   * this will be the readOnlyGrade. If a user has multiple grades, we will select the grade type
   * after these rules:
   * 1. If there is only one grade, we select that grade type
   * 2. If all user grades belong to the same grade type, we select that grade type
   * 3. If the user has multiple grades, we select the grade type with the most grades
   *    a: readOnlyGrades only count as half a grade, in order to prioritize the other selected
   *       grade higher than the readOnlyGrade, as the readOnlyGrade can not be changed.
   * 4. If the user has multiple grades, but no grade type has higher score than the others grades,
   *    we select the lowest grade type of the highest ranked grade types.
   */
  const selectedGradeType = computed((): GradeType | undefined => {
    if (!user.value|| user.value.grades.length === 0) return undefined
    if (user.value.grades.length === 1) return findRelevantGradeType(user.value.grades[0])

    const allUserGradesBelongToSameGradeType = user.value.grades.map(findRelevantGradeType).filter(unique).length === 1
    if (allUserGradesBelongToSameGradeType) return findRelevantGradeType(user.value.grades[0])

    const gradeTypeCounts = user.value.grades.reduce((gradeTypes, grade) => {
      const gradeType = findRelevantGradeType(grade)
      if (!gradeType) return gradeTypes
      if (!gradeTypes[gradeType]) gradeTypes[gradeType] = 0
      if (isReadOnlyGrade(grade)) gradeTypes[gradeType] += .5
      else gradeTypes[gradeType] += 1
      return gradeTypes
    }, {} as Record<GradeType, number>)

    const gradeTypesSortedByGradeWeighting = Object.entries(gradeTypeCounts)
      .sort((gradeTypeCountA, gradeTypeCountB) => {
        if (gradeTypeCountA[1] === gradeTypeCountB[1]) return 0
        return gradeTypeCountA[1] > gradeTypeCountB[1] ? -1 : 1
      })

    if (gradeTypesSortedByGradeWeighting[0][0] && gradeTypesSortedByGradeWeighting[0][0] > gradeTypesSortedByGradeWeighting[1][0]) {
      return gradeTypesSortedByGradeWeighting[0][0] as GradeType
    }

    const possibleGradeTypes = gradeTypesSortedByGradeWeighting
      .filter(([, count]) => count === gradeTypesSortedByGradeWeighting[0][1])
      .map(([gradeType]) => gradeType as GradeType)
      // sort the gradeTypes based on the position in the GradeType enum:
      .sort((a, b) => Object.values(GradeType).indexOf(a) - Object.values(GradeType).indexOf(b))

    return possibleGradeTypes[0]
  })

  const selectedGradeTypeGrades = computed(() => selectedGradeType.value
  ? findGradesByGradeType(selectedGradeType.value)
  : [])

  /**
   * The way we set gradeType is by simply selecting all grades in the gradeType. For upperSecondary
   * we also added the possibility to limit the grades to fewer than all of them.
   */
  function setGradeType(type: GradeType, limitToGrades?: GradeCode[]) {
    if (!user.value) throw new Error('Missing user')

    limitToGrades = limitToGrades?.filter((grade) => findGradesByGradeType(type).includes(grade))

    user.value.grades = [
      ...(limitToGrades?.length ? limitToGrades : findGradesByGradeType(type)),
      ...userReadOnlyGrades.value,
    ].filter(unique)

    let preferredGrade = findGradesByGradeType(type).sort(sortByGradeIndex)[0]
    if (intersect(userReadOnlyGrades.value, findGradesByGradeType(type)).length > 0) {
      preferredGrade = userReadOnlyGrades.value[0]
    } else {
      // at this point, preferredGrade is either not set or is a grade outside of the current
      // gradeType, so let's set it to the first grade in the current grade type instead
      preferredGrade = findGradesByGradeType(type).sort(sortByGradeIndex)[0]
    }

    setPreferredGrade(preferredGrade)
  }

  /**
   * SessionUserInfo is the object used upstream
   */
  const sessionUserInfo = computed((): SessionUserInfo => ({
    grades: userGrades.value,
    subjects: userSubjects.value,
    preferredLanguage: userPreferredLanguage.value,
    preferredGrade: userPreferredGrade.value,
    termsOfServiceAccepted: true,
    activeUserFilters: activeUserFilters.value,
  }))

  async function acquireSession() {
    isLoading.value = true
    try {
      user.value = await getUser()
      sessionInterval()
    } catch (error) {
      user.value = undefined
      throw error
    } finally {
      isLoading.value = false
    }
  }

  function sessionInterval() {
    const router = useRouter()
    const seconds = parseInt(import.meta.env.VITE_OIDC_INTERVAL)
    const session = setInterval(async () => {
      try {
        await getUser()
      } catch (error) {
        clearInterval(session)
        user.value = undefined
        await router.push({
          name: 'login',
          query: { redirectUri: window.location.toString() },
        })
      }
    }, seconds * 1000)
  }

  async function updateUser(sessionUser: SessionUserInfo) {
    if (!user.value) throw Error('Missing user')
    isLoading.value = true
    try {
      user.value.userData.activeUserFilters = sessionUser.activeUserFilters
      user.value = await postUser(sessionUser)
    } finally {
      isLoading.value = false
      updatePendo(user.value)
    }
  }

  async function updateUserOrganization(organization: Organization) {
    if (!user.value) throw Error('Missing user')
    isLoading.value = true
    try {
      user.value.organization = await postUserOrganization(organization)
    } finally {
      isLoading.value = false
      updatePendo(user.value)
    }
  }

  function toggleGrade(grade: GradeCode) {
    if (!user.value) throw Error('Missing user')
    let grades = [...user.value.grades]
    if (grades.includes(grade)) {
      grades = grades.filter((code) => code !== grade || isReadOnlyGrade(code))
    } else {
      grades.push(grade)
    }
    user.value = {
      ...user.value,
      grades,
    }
  }

  function isReadOnlyGrade(grade: GradeCode) {
    return userReadOnlyGrades.value.includes(grade)
  }

  function isPreferredLanguage(preferredLanguage: PreferredLanguage) {
    return preferredLanguage === user.value?.preferredLanguage
  }

  function setPreferredLanguage(preferredLanguage: PreferredLanguage) {
    if (!user.value) throw Error('Missing user')
    user.value.preferredLanguage = preferredLanguage
  }

  function setPreferredGrade(preferredGrade: GradeCode | undefined) {
    const { setSelectedGrade } = useFilterStore()
    if (!preferredGrade) return
    if (!user.value) throw Error('Missing user')
    user.value.preferredGrade = preferredGrade

    updateUser(sessionUserInfo.value).then(() => {
      if (user.value?.preferredGrade) setSelectedGrade(user.value.preferredGrade)
    })
  }

  function setActiveOrganization(organization: Organization) {
    if (!user.value) throw Error('Missing user')
    user.value.organization = organization
  }

  watch(isAuthenticated, () => {
    if (user.value) {
      initPendo(user.value)
    }
  })

  watch(() => [isAuthenticated, user, hasOnlyTrialProducts], ([isAuthenticated, user, hasOnlyTrialProducts]) => {
    if (undefined === isAuthenticated.value) return
    if (undefined === user.value) return
    if (undefined === hasOnlyTrialProducts.value) return

    updatePendo(<User>user.value, <boolean>hasOnlyTrialProducts.value)
  }, { deep: true })

  return {
    user,
    username,
    isLoading,
    isAuthenticated,
    hasGrades,
    hasSubjects,
    hasPreferredLanguage,
    userGrades,
    userReadOnlyGrades,
    userSubjects,
    userRole,
    userOrganization,
    userOrganizations,
    needsOrganization,
    hasMultipleOrganizations,
    userPreferredLanguage,
    userPreferredLanguageCode,
    userPreferredGrade,
    isTeacher,
    isStudent,
    hasOrganization,
    activeUserFilters,
    sessionUserInfo,
    acquireSession,
    updateUser,
    updateUserOrganization,
    toggleGrade,
    isReadOnlyGrade,
    isPreferredLanguage,
    setPreferredLanguage,
    setPreferredGrade,
    userId,
    userSubjectsByGrades,
    userRelevantGrades,
    userGradesBySubjects,
    userGradesByGradeType,
    isUpperSecondaryAdultOrPrepUser,
    activeUserGroups,
    activeUserGroup,
    setUserActiveGroup,
    selectedGradeType,
    setGradeType,
    selectedGradeTypeGrades,
  }
})

export default useAuthStore
