<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import type { ContentPackage } from '~/models/Content/ContentPackage'
import type { Product } from '~/models/Product'
import type { ContentResource } from '~/models/Content/ContentResource'
import type { ColorPair } from '~/models/Presentation/BasePage'
import { computed, ref } from 'vue'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'
import { useContentHelper } from '~/composables/useContentHelper'
import useColors from '~/composables/colors'

const { getTitle } = useContentHelper()
const { buildPackageUrl } = useUrlBuilder()

const props = withDefaults(defineProps<{
  resource: BaseItem|ContentPackage|ContentResource
  isAnchor?: boolean
  hasAccess?: boolean
  product?: Product
  colorPair?: ColorPair
}>(), {
  isAnchor: false,
  hasAccess: true,
  product: undefined
})

const elementRef = ref()

const { backgroundStyle } = useColors({
  identifier: props.resource.locationId!,
  elementRef,
})

const title = computed(() => getTitle(props.resource))
const link = computed(() => buildPackageUrl(props.resource))
const imageIsFallback = computed(() => (props.resource as ContentResource)?.imageIsFallback ?? false)
</script>
<template>
  <article
    class="relative h-full min-h-[200px] min-w-[215px] rounded-2xl text-lg motion-safe:animate-fade-in-down"
    :class="{'h-[230px] w-[250px]': colorPair}"
  >
    <div
      ref="elementRef"
      class="relative h-full max-w-full overflow-hidden rounded-2xl"
      :class="colorPair ? 'aspect-auto' : 'aspect-square'"
    >
      <img
        v-if="resource.thumbnail"
        :src="resource.thumbnail"
        class="block h-full w-full rounded-xl object-cover"
        :class="{'opacity-0': imageIsFallback}"
        loading="lazy"
        :alt="resource.thumbnailAlt"
        aria-hidden="true"
      >
      <div
        v-else
        class="absolute inset-0"
        :style="backgroundStyle"
      />
    </div>
    <div class="absolute bottom-0 z-10 flex h-full w-full flex-col items-center justify-end rounded-2xl bg-gray-50/10 text-center text-white">
      <div class="h-max min-w-[50%] max-w-[80%] rounded-t-xl bg-white px-4 py-3">
        <span class="line-clamp-3 overflow-hidden hyphens-auto text-base font-semibold text-blue-50 sm:text-lg">
          <LinkOrAnchor
            :link="link"
            class="outline-0 before:absolute before:inset-0 before:z-10 before:rounded-2xl before:shadow-md before:transition-shadow before:hover:shadow-lg before:focus-visible:shadow-lg before:focus-visible:ring"
            :is-anchor="props.isAnchor"
          >
            {{ title }}
          </LinkOrAnchor>
        </span>
      </div>
    </div>
  </article>
</template>
