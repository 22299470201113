<script lang="ts" setup>
import type { ContentResource } from '~/models/Content/ContentResource'
import { computed, ref } from 'vue'
import { KsCarousel, KsCarouselItem } from '@aschehoug/kloss'
import useColors from '~/composables/colors'

export interface CollectionProps {
  props: ContentResource
  resources: ContentResource[]
}

const { props, resources } = defineProps<CollectionProps>()

const backgroundElement = ref()

const { backgroundStyle, needsDarkText } = useColors({
  identifier: props.locationId!,
  backgroundColor: props.colors[0],
  backgroundFormatter: (color) => `linear-gradient(180deg, ${color}, ${color}E6)`,
  elementRef: backgroundElement,
})

const textStyle = computed(() =>
  needsDarkText.value
    ? 'text-black'
    : 'text-white')
</script>

<template>
  <section
    v-if="resources.length"
    ref="backgroundElement"
    class="overflow-hidden py-8 sm:py-14"
    :style="backgroundStyle"
  >
    <div
      class="m-auto max-w-screen-au px-4 sm:px-8"
      :class="textStyle"
    >
      <h2
        class="mb-2 text-center text-3xl font-bold sm:text-4xl"
        v-text="props.title"
      />
      <p
        v-if="props.description"
        class="m-auto mb-2 max-w-prose text-center text-base"
        v-html="props.description"
      />
      <KsCarousel>
        <KsCarouselItem
          v-for="(item, i) in resources"
          :key="i"
        >
          <slot
            name="resource"
            :resource-item="item"
          />
        </KsCarouselItem>
      </KsCarousel>
    </div>
  </section>
</template>
