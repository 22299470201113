import type { SectionId, SectionItem } from '~/models/Section'
import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useResourceApi } from '~/api/resourceApi'
import { useAuthStore } from './auth'
import arrayUtils from '~/utils/arrayUtils'
import useFilterStore from '~/stores/filter'

export const useSectionStore = defineStore('section', () => {
  const { getSectionsById: loadSectionsById } = useResourceApi()
  const authStore = useAuthStore()
  const { userRelevantGrades, userSubjectsByGrades } = storeToRefs(authStore)
  const { selectedGrade } = storeToRefs(useFilterStore())
  const { intersect, empty } = arrayUtils()

  const loading = ref(false)
  const sections = ref<Record<SectionId, SectionItem[]>>({})
  const sectionId = ref<SectionId>(0)
  const isLoading = computed(() => loading.value)

  const startLoading = () => loading.value = true
  const stopLoading = () => loading.value = false

  const setSections = (id: SectionId, items: SectionItem[]) => sections.value[id] = items
  const getSectionsById = (id: SectionId): SectionItem[] => sections.value[id] ?? []

  const loadSections = async (id: SectionId) => {
    sectionId.value = id
    if (getSectionsById(id).length) return
    startLoading()
    setSections(id, await loadSectionsById(id))
    stopLoading()
  }

  const userSections = computed(() => getSectionsById(sectionId.value)
    .filter(({ props: { grades, subjects } }) => {
      const hasGrades = empty(grades) || !empty(intersect(grades, userRelevantGrades.value))
      const hasSubjects = empty(subjects) || !empty(intersect(subjects, userSubjectsByGrades.value))
      return hasGrades && hasSubjects
    })
    .map((section) => ({
      ...section,
      resources: section.resources.filter(({ grades }) => empty(grades) || !empty(intersect(grades, userRelevantGrades.value))),
      featured: section.featured.filter(({ grades }) => empty(grades) || !empty(intersect(grades, userRelevantGrades.value))),
    }))
  )

  const subjectSections = computed(() => getSectionsById(sectionId.value)
    .filter(({ props: { grades, subjects } }) => {
      const hasGrades = empty(grades) || !empty(intersect(grades, [selectedGrade.value]))
      const hasSubjects = empty(subjects) || !empty(intersect(subjects, [sectionId.value]))
      return hasGrades && hasSubjects
    })
    .map((section) => ({
      ...section,
      resources: section.resources.filter(({ grades }) => empty(grades) || !empty(intersect(grades, [selectedGrade.value]))),
      featured: section.featured.filter(({ grades }) => empty(grades) || !empty(intersect(grades, [selectedGrade.value]))),
    }))
  )

  const subjectRowButtonSubjects = computed(() => (
    userSections.value.filter(({ component, props }) => {
      if (component !== 'SubjectRowButton') return false
      return props.removeSubject || false
    }).flatMap(({ props }) => props.subjects)
  ))

  return {
    loading,
    isLoading,
    startLoading,
    stopLoading,
    loadSections,
    setSections,
    getSectionsById,
    userSections,
    subjectSections,
    subjectRowButtonSubjects,
    sections,
  }
})
