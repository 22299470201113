<script setup lang="ts">
import type { ContentPackage } from '~/models/Content/ContentPackage'
import type { ContentImage } from '~/models/Content/ContentImage'
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import useContentApi from '~/api/contentApi'
import useFilterStore from '~/stores/filter'
import { setTitle } from '~/utils/dom'
import useSubjectsStore from '~/stores/subjects'
import useProductStore from '~/stores/product'
import useFilterSettingsStore from '~/stores/filterSettings'
import { useContentHelper } from '~/composables/useContentHelper'
import useQueryFilter from '~/composables/useQueryFilter'
import ResourceFinder from '~/components/ResourceFinder/ResourceFinder.vue'
import PackageBanner from '~/components/banners/PackageBanner.vue'
import PackageViewSkeleton from '~/components/skeletons/PackageViewSkeleton.vue'
import ProductPackageIntro from '~/components/ResourceFinder/ProductPackageIntro.vue'

const props = defineProps<{ locationId: number }>()

const router = useRouter()
const { resetFilterOptions } = useFilterSettingsStore()
const { findContents, findImageContent } = useContentApi()
const { setCurrentSubject } = useSubjectsStore()
const { packageTypes } = useContentHelper()

const filterStore = useFilterStore()
const { setSelectedAddonLocationId, filterWatchers, $reset, setSelectedHeader } = filterStore
const { isLoading: isLoadingStore } = storeToRefs(filterStore)

const watchers = filterWatchers()
onMounted(() => useQueryFilter())

onBeforeUnmount(() => {
  watchers.stopFilterWatchers()
  $reset()
})

const content = ref<ContentPackage>()
const image = ref<ContentImage>()
const isLoadingPackage = ref(false)
const isLoadingChildren = ref(false)

const packageName = computed(() => content.value?.title)
const packageBackground = computed(() => image.value?.palette)
const isLoading = computed(() => isLoadingPackage.value || isLoadingChildren.value)

const loadImage = async (content?: ContentPackage) => {
  if (!content || !content?.image?.destinationContentId) return
  image.value = await findImageContent(Number(content.image.destinationContentId))
}

const loadPackage = async (locationId: number) => {
  try {
    isLoadingPackage.value = true
    const contentValue = (await findContents<ContentPackage>({
      locationIdCriterion: [locationId],
      contentTypeCriterion: packageTypes,
    }, 1))[0]
    if (!contentValue) throw Error(`Package ${locationId} not found`)
    content.value = contentValue
    setSelectedHeader([content.value])
  } catch (e) {
    content.value = undefined
    await router.replace({ name: 'notfound', query: { sti: router.currentRoute.value.fullPath } })
    throw e
  } finally {
    isLoadingPackage.value = false
  }
}

onBeforeMount(async () => {
  setCurrentSubject(undefined)
  await loadPackage(props.locationId).then(() => loadImage(content.value))
})

onBeforeUnmount(() => {
  setSelectedAddonLocationId(undefined)
  resetFilterOptions()
  $reset()
})

watch(props, () => loadPackage(props.locationId).then(() => loadImage(content.value)))

watch(content, () => content.value && setTitle(content.value.title))

const { getProductFromResource } = useProductStore()

const hasProduct = computed(() => content.value && getProductFromResource(content.value))
const showIntro = computed(() => content.value && content.value?.intro.length > 2)
</script>

<template>
  <PackageBanner
    :title="packageName"
    :background="packageBackground"
  />
  <div
    v-if="!isLoadingPackage && !isLoadingStore"
    class="mx-auto max-w-screen-au px-4 sm:px-8"
  >
    <ProductPackageIntro
      v-if="showIntro"
      :package="content"
    />
  </div>
  <PackageViewSkeleton
    v-if="isLoading"
    :is-loading-package="isLoadingPackage"
    :is-loading-children="isLoadingChildren"
  />
  <ResourceFinder
    v-if="!isLoadingChildren"
    :is-anchor="!hasProduct"
    :show-filter="false"
    :enable-subject-filter="false"
    :enable-grade-filter="false"
  />
</template>
