<script setup lang="ts">
import { KsButton } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { computed, onMounted } from 'vue'
import useBlogStore from '~/stores/blog'
import NewsCard from '~/components/cards/NewsCard.vue'

const props = defineProps<{ limit: number }>()

const { t } = useI18n()
const { getPosts } = useBlogStore()
const { postsByGrades } = storeToRefs(useBlogStore())

const posts = computed(() => postsByGrades.value.slice(0, props.limit))

onMounted(() => getPosts(props.limit * 3))
</script>

<template>
  <section
    v-if="postsByGrades.length"
    class="mx-auto mb-12 w-full max-w-screen-au space-y-5 px-4 sm:px-8"
  >
    <slot name="title">
      <h2
        class="text-xl font-bold text-gray-50"
        v-text="t('blog.title')"
      />
    </slot>
    <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <NewsCard
        v-for="(post, i) in posts"
        :key="i"
        :blog-post="post"
      />
    </ul>
    <RouterLink
      :to="{ name: 'news' }"
      class="inline-flex rounded-xl focus-visible:ring"
    >
      <KsButton
        variant="border"
        type="label"
        icon-right="arrow-right"
      >
        {{ t('blog.seAllPosts') }}
      </KsButton>
    </RouterLink>
  </section>
</template>
