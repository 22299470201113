import type { ContentBlogPost } from '~/models/Content/ContentBlogPost'
import { Subtree } from '~/models/Content/Subtree'
import { RemoteId } from '~/models/Content/RemoteId'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useSearch } from '~/composables/useSearch'
import useContentMapper from '~/composables/useContentMapper'
import useSearchHelper from '~/composables/useSearchHelper'
import { useAuthStore } from '~/stores/auth'
import arrayUtils from '~/utils/arrayUtils'

const useBlogStore = defineStore('blog', () => {
  const { mapLocations, mapBlogPost } = useContentMapper()
  const { searchPath, emptyQuery } = useSearchHelper()
  const { userGrades } = storeToRefs(useAuthStore())
  const { intersect } = arrayUtils()

  const posts = ref<ContentBlogPost[]>([])
  const isLoading = ref(false)

  const getPosts = async (limit?: number) => {
    const { fetchResults, results } = useSearch<ContentBlogPost[]>(searchPath, {
      transformData: (response) => mapLocations(response.View.Result).map(mapBlogPost)
    })
    isLoading.value = true
    try {
      await fetchResults(emptyQuery, {
        parentLocationIdCriterion: [ RemoteId.BlogPostsFolder ],
        subtreeCriterion: [ Subtree.App ],
        sortField: 'published',
        sortOrder: 'desc',
      }, limit ?? 100)

      posts.value = [...(results.value ?? [])]
    } catch (error) {
      posts.value = []
      throw error
    } finally {
      isLoading.value = false
    }
  }

  const postTags = computed(() => {
    const tags = posts.value
      .flatMap((post) => (post.tags || []))
      .map((tag) => tag.toLowerCase())
    return [...new Set(tags)]
  })

  const postsByGrades = computed(() => posts.value.filter(({ grades }) =>
    !grades.length
    || !userGrades.value.length
    || intersect(userGrades.value, grades).length > 0)
  )

  const getPostsByTag = (tag: string) => posts.value.filter((post) => post.tags.includes(tag))

  const getPostById = (id: number) => posts.value.find(({ locationId }) => locationId === id)

  return {
    getPosts,
    getPostsByTag,
    getPostById,
    postTags,
    posts,
    isLoading,
    postsByGrades,
  }
})

export default useBlogStore
