<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, ref, watch, provide } from 'vue'
import { KsButton, KsCallout, KsCarousel, KsCarouselItem, KsDialog } from '@aschehoug/kloss'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { useI18n } from 'vue-i18n'
import { useContentHelper } from '~/composables/useContentHelper'
import useContentApi from '~/api/contentApi'
import CardRenderer from '~/components/cards/CardRenderer.vue'
import CardSkeleton from '~/components/skeletons/CardSkeleton.vue'
import { useAuthStore } from '~/stores/auth'
import { storeToRefs } from 'pinia'

const { isTeacher } = storeToRefs(useAuthStore())
const { isProductArticle, isProductPart } = useContentHelper()
const { findChildren } = useContentApi()
const { t } = useI18n()

const props = defineProps<{ resource: BaseItem }>()

const dialogOpen = ref(false)
const isLoading = ref(false)
const showErrorCallout = ref(false)
const learningPathResources = ref<BaseItem[]>([])

const getLearningPathChildren = async (item: BaseItem) => {
  try {
    isLoading.value = true
    learningPathResources.value = await findChildren([item.mainLocationId])
  } catch (error) {
    learningPathResources.value = []
    showErrorCallout.value = true
    throw error
  } finally {
    isLoading.value = false
  }
}

const filteredLearningPathResources = computed(() =>
  learningPathResources.value.filter((resource) => isProductPart(resource) || isProductArticle(resource)))

watch(dialogOpen, () => {
  if (dialogOpen.value) return getLearningPathChildren(props.resource)
  return
})

// Give resource cards information about where they are shown
provide('inStudentActivityDialog', true)

</script>

<template>
  <KsButton
    size="small"
    shape="square"
    variant="secondary"
    icon-left="collection"
    :aria-label="t('learningPath.learningPathOverview')"
    @click="dialogOpen = true"
  />
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      title=""
      :open="dialogOpen"
      :strict="false"
      size="large"
      @close="dialogOpen = false"
    >
      <template #header>
        <div class="flex items-center gap-5">
          <h1
            class="text-2xl font-medium"
            v-text="resource.title"
          />
          <p
            v-if="filteredLearningPathResources.length"
            class="text-gray-40"
            v-text="t('learningPath.learningPathParts', { partsCount: filteredLearningPathResources.length })"
          />
        </div>
      </template>
      <template #body>
        <div
          v-if="isLoading"
          class="px-4"
        >
          <KsCarousel>
            <KsCarouselItem
              v-for="i in 5"
              :key="i"
              class="w-full"
            >
              <CardSkeleton width="215px" />
            </KsCarouselItem>
          </KsCarousel>
        </div>
        <div v-if="showErrorCallout || !filteredLearningPathResources.length && !isLoading">
          <KsCallout
            variant="warning"
            :title="t('learningPath.noResourcesFound')"
          />
        </div>
        <div
          v-if="!isLoading && !showErrorCallout && filteredLearningPathResources.length"
          class="px-4"
        >
          <KsCarousel>
            <KsCarouselItem
              v-for="pathResource in filteredLearningPathResources"
              :key="pathResource.locationId"
            >
              <CardRenderer :resource="pathResource" />
            </KsCarouselItem>
          </KsCarousel>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>
