<script setup lang="ts">
import type { FileField } from '~/models/Content/BaseField'
import type { ContentFile } from '~/models/Content/ContentFile'
import { KsButton } from '@aschehoug/kloss'
import { computed } from 'vue'
import useFile from '~/composables/useFile'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { ContentType } from '~/models/Content/ContentType'
import { Section } from '~/models/Content/Section'
import { useRouter } from 'vue-router'

const props = defineProps<{ file: FileField }>()

const router = useRouter()
const { readableMimeType } = useFile()
const { buildResourceUrl } = useUrlBuilder()

const href = computed(() => {
  if (!props.file.uri) return router.resolve({ name: 'notfound' }).href
  return buildResourceUrl({
    file: props.file,
    contentTypeIdentifier: ContentType.File,
    sectionId: Section.Standard,
  } as ContentFile)
})

const fileNameAndType = computed(() => {
  const fileType = readableMimeType(props.file.mimeType)
  if (!fileType) return props.file.fileName
  return `${props.file.fileName} (${fileType})`
})

</script>
<template>
  <KsButton
    :href="href"
    variant="secondary"
    class="flex w-full justify-between hover:!bg-orange-30/40"
    style="--ks-input: white;"
    icon-right="download"
  >
    <span class="text-ellipsis overflow-x-hidden">{{ fileNameAndType }}</span>
  </KsButton>
</template>
