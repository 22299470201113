<script setup lang="ts">
import { KsButton } from '@aschehoug/kloss'
import useText from '~/composables/useText'
import { useI18n } from 'vue-i18n'

defineProps<{
  labelCriterion?: string[]
  activityCriterion?: string[]
  gradeCriterion?: string[]
  accessCriterion?: string[]
  selectedSubjects?: string[]
}>()

const emit = defineEmits([
  'resetLabelCriterion',
  'resetActivityCriterion',
  'addGradeCriterion',
  'addSubjectCriterion',
  'resetCriterions',
  'resetAccessCriterion',
])

const { t } = useI18n()
const { capitalize } = useText()
</script>

<template>
  <ul
    class="flex flex-wrap gap-3"
    :aria-label="t('search.filters.activeFilters')"
  >
    <li
      v-for="subject in selectedSubjects"
      :key="subject"
    >
      <KsButton
        variant="primary"
        icon-right="xmark"
        shape="rounded"
        size="small"
        :aria-label="t('search.filters.removeFilter', { filter: t(`metadata.subjects.${subject}`) })"
        @click="emit('addSubjectCriterion', subject)"
      >
        {{ t(`metadata.subjects.${subject}`) }}
      </KsButton>
    </li>
    <li
      v-for="grade in gradeCriterion"
      :key="grade"
    >
      <KsButton
        variant="primary"
        icon-right="xmark"
        shape="rounded"
        size="small"
        :aria-label="t('search.filters.removeFilter', { filter: t(`metadata.grades.${grade}`) })"
        @click="emit('addGradeCriterion', grade)"
      >
        {{ t(`metadata.grades.${grade}`) }}
      </KsButton>
    </li>
    <li v-if="labelCriterion?.length">
      <KsButton
        variant="primary"
        icon-right="xmark"
        shape="rounded"
        size="small"
        :aria-label="t('search.filters.removeFilter', { filter: t(`labels.${labelCriterion}`) })"
        @click="emit('resetLabelCriterion')"
      >
        {{ t(`labels.${labelCriterion}`) }}
      </KsButton>
    </li>
    <li v-if="activityCriterion?.length">
      <KsButton
        variant="primary"
        icon-right="xmark"
        shape="rounded"
        size="small"
        :aria-label="t('search.filters.removeFilter', { filter: t(`activities.${activityCriterion[0]}`) })"
        @click="emit('resetActivityCriterion')"
      >
        {{ t(`activities.${activityCriterion[0]}`, capitalize(activityCriterion[0])) }}
      </KsButton>
    </li>
    <li v-if="accessCriterion?.length">
      <KsButton
        variant="primary"
        icon-right="xmark"
        shape="rounded"
        size="small"
        :aria-label="t('search.filters.removeFilter', { filter: t(`access.${accessCriterion}`) })"
        @click="emit('resetAccessCriterion')"
      >
        {{ t(`access.${accessCriterion}`) }}
      </KsButton>
    </li>
    <li>
      <KsButton
        variant="border"
        shape="rounded"
        size="small"
        :aria-label="t('search.filters.removeAllFilters')"
        @click="emit('resetCriterions')"
      >
        {{ t('search.reset') }}
      </KsButton>
    </li>
  </ul>
</template>
