<script setup lang="ts">
import { computed } from 'vue'
import { colorIsLight } from '~/utils/colors'

const props = withDefaults(defineProps<{
  title: string | undefined
  text?: string
  image?: string
  background?: string
}>(), {
  title: '',
})

const backgroundStyle = computed(() =>
  props.background
    ? props.background
    : '#095456',
)

const textStyle = computed(() => {
  return colorIsLight(backgroundStyle.value) ? 'text-black' : 'text-white'
})
</script>

<template>
  <header
    class="relative flex-col justify-start bg-cover bg-center bg-no-repeat pb-8 pt-40 text-white sm:pt-32"
    :style="{background: backgroundStyle}"
  >
    <div class="relative z-20 mx-auto max-w-screen-au px-4 sm:px-8">
      <h1
        :class="textStyle"
        class="inline-block text-3xl font-semibold md:text-4xl"
        v-text="title"
      />
      <p v-html="text" />
    </div>
  </header>
</template>
