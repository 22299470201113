<script setup lang="ts">
import type { ViewMode } from '~/models/Content/BaseField'
import { computed, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import useFilterStore from '~/stores/filter'
import useTime from '~/composables/useTime'
import { useContentFilter } from '~/composables/useContentFilter'
import ResourceFinderFilter from '~/components/ResourceFinder/ResourceFinderFilter.vue'
import CardSkeleton from '~/components/skeletons/CardSkeleton.vue'
import ResourceFinderFilterSkeleton from '~/components/skeletons/ResourceFinderFilterSkeleton.vue'
import ContentFilteredAwayCallout from '~/components/ResourceFinder/ContentFilteredAwayCallout.vue'
import ProductPackageIntro from '~/components/ResourceFinder/ProductPackageIntro.vue'
import CardRenderer from '~/components/cards/CardRenderer.vue'

const filterStore = useFilterStore()
const { currentWeekNumber } = useTime()
const {
  isLoading: storeIsLoading,
  lastSelectedHeader,
  viewMode: currentViewMode,
  fileView,
} = storeToRefs(filterStore)

// because storeIsLoading is toggled a few times before it really settles on a value, we have to do this:
const isLoading = ref(storeIsLoading.value)
let loadingTimeout: ReturnType<typeof setTimeout>
watch(storeIsLoading, () => {
  clearTimeout(loadingTimeout)
  loadingTimeout = setTimeout(() => {
    isLoading.value = storeIsLoading.value
  }, 100)
})

const props = withDefaults(defineProps<{
  showFilter?: boolean
  showPackageIntro?: boolean
  isAnchor?: boolean
  enableGradeFilter?: boolean
  enableSubjectFilter?: boolean
  viewMode?: ViewMode
}>(), {
  showFilter: true,
  showPackageIntro: false,
  isAnchor: false,
  enableGradeFilter: true,
  enableSubjectFilter: true,
})

const currentWeek = ref(currentWeekNumber)

const { contents, filteredContents, filteredProductPackages }  = useContentFilter(
  props.showFilter,
  props.enableGradeFilter
)

const autoSelectFilter = computed(() => props.showFilter
  && filteredContents.value.length === 1
  && filteredProductPackages.value.length === 1)

const showIntro = computed(() => props.showPackageIntro
  && lastSelectedHeader.value
  && lastSelectedHeader.value?.intro.length > 2)

const resolveViewMode = computed(() => props.viewMode ?? currentViewMode.value)

const hasContents = computed(() => filteredContents.value?.length)
</script>
<template>
  <ResourceFinderFilterSkeleton v-if="isLoading" />
  <ResourceFinderFilter
    v-if="showFilter"
    v-show="!isLoading"
    :auto-select="autoSelectFilter"
    :enable-grade-filter="enableGradeFilter"
    :enable-subject-filter="enableSubjectFilter"
  />
  <section class="relative m-auto mb-12 max-w-screen-au px-4 sm:px-8">
    <ul
      v-if="isLoading"
      class="mb-12 grid grid-cols-1 gap-6 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
    >
      <li
        v-for="i in 8"
        :key="i"
      >
        <CardSkeleton />
      </li>
    </ul>
    <template v-else>
      <ProductPackageIntro
        v-if="showIntro"
        :package="lastSelectedHeader"
      />
      <ContentFilteredAwayCallout
        v-if="!hasContents && contents.length > 0 && !storeIsLoading"
        :contents="contents"
        :enable-subject-filter="enableSubjectFilter"
      />
      <ul
        class="mt-8 grid grid-cols-1"
        :class="{
          'gap-3 sm:grid-cols-2': fileView,
          'gap-6 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5': !fileView,
        }"
      >
        <li
          v-for="content of filteredContents"
          :key="`resource-${content.locationId}week-${currentWeek}`"
          :class="{ 'border-b border-gray-10 pb-3': fileView }"
        >
          <CardRenderer
            :resource="content"
            :view-mode="resolveViewMode"
            :is-anchor="props.isAnchor"
          />
        </li>
      </ul>
    </template>
  </section>
</template>
