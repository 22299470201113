<script setup lang="ts">
import type { Product } from '~/models/Product'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import { KsButton } from '@aschehoug/kloss'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import useSlugify from '~/composables/useSlugify'
import { sortByNameGradeSubject } from '~/utils/productSorter'
import router from '~/router'
import DefaultGreetings from '~/components/home/DefaultGreetings.vue'
import DefaultIllustration from '~/components/home/DefaultIllustration.vue'

const { t } = useI18n()
const { intersect } = arrayUtils()
const authStore = useAuthStore()
const { userSubjectsByGrades, userGrades } = storeToRefs(authStore)
const { addonProducts } = storeToRefs(useProductStore())
const { slugify } = useSlugify()

const hasUserGrades = (product: Product) => !product.grades.length
  || intersect(product.grades, userGrades.value).length > 0

const filteredAddonProducts = computed(() => [...addonProducts.value]
  .filter((product) => hasUserGrades(product))
  .sort(sortByNameGradeSubject))

const buildProductUrl = (product: Product) => {
  const { href } = router.resolve({
    name: 'product',
    params: {
      locationId: product.aunivers.locationId,
      slug: slugify(product.aunivers.name),
    },
  })
  return href
}
</script>

<template>
  <header class="relative mb-12 flex-col justify-center bg-gradient-to-tr from-seagreen-40 to-custom-green to-60% text-white">
    <div class="relative z-20 mx-auto h-full w-full max-w-screen-au px-4 pb-10 pt-40 transition-all xs:pb-14 sm:px-8 sm:pt-28">
      <h1
        class="sr-only"
        v-text="t('home.title')"
      />
      <DefaultGreetings />
      <p
        v-if="userSubjectsByGrades.length"
        class="mb-2 mt-4 font-medium"
        v-text="t('home.yourSubjects')"
      />
      <ul
        v-if="userSubjectsByGrades.length"
        :id="TeleportationTarget.SubjectRowButtons.replace('#', '')"
        class="
          relative z-10 -mx-4 flex h-fit gap-3 overflow-x-scroll
          px-4 pb-4 xs:flex-wrap xs:overflow-x-visible xs:pb-0 md:max-w-[70%]
        "
      >
        <li
          v-for="subjectCode of userSubjectsByGrades"
          :key="subjectCode"
        >
          <RouterLink
            :to="{ name: 'subject', params: { subject: subjectCode.toLowerCase() } }"
            class="inline-flex rounded-full transition focus-visible:ring"
          >
            <KsButton
              variant="secondary"
              type="label"
              shape="rounded"
              style="--ks-input: white"
            >
              {{ t(`metadata.subjects.${subjectCode}`) }}
            </KsButton>
          </RouterLink>
        </li>
      </ul>
      <p
        v-if="filteredAddonProducts.length"
        class="mb-2 mt-6 font-medium"
        v-text="t('home.yourAddons')"
      />
      <ul
        v-if="filteredAddonProducts.length"
        class="relative z-10 -mx-4 flex h-fit gap-3 overflow-x-scroll px-4 pb-4 xs:flex-wrap xs:overflow-x-visible xs:pb-0 md:max-w-[70%]"
      >
        <li
          v-for="addon in filteredAddonProducts"
          :key="addon.ean"
        >
          <RouterLink
            :to="{ path: buildProductUrl(addon) }"
            class="inline-flex rounded-full transition focus-visible:ring"
          >
            <KsButton
              variant="secondary"
              type="label"
              shape="rounded"
              style="--ks-input: white"
            >
              {{ addon.aunivers.name }}
            </KsButton>
          </RouterLink>
        </li>
      </ul>
      <DefaultIllustration />
    </div>
  </header>
</template>
