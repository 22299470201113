<script setup lang="ts">
import type { ColorTheme } from '~/models/Content/Color'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { computed, onMounted, watch } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { PendoFeature } from '~/models/Pendo'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import useProductStore from '~/stores/product'
import { useContentFilter } from '~/composables/useContentFilter'
import { useContentHelper } from '~/composables/useContentHelper'
import { useAppColor } from '~/composables/useAppColor'
import { useMedia } from '~/composables/useMedia'
import { colorMap } from '~/utils/colors'
import Image from '~/components/media/Image.vue'
import GridCard from '~/components/cards/GridCard.vue'
import SimpleCard from '~/components/cards/SimpleCard.vue'
import HeaderFilter from '~/components/ResourceFinder/HeaderFilter.vue'
import HeaderTeacherButton from '~/components/subject/HeaderTeacherButton.vue'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import GradeFilter from '~/components/ResourceFinder/GradeFilter.vue'
import SubjectFilter from '~/components/ResourceFinder/SubjectFilter.vue'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import HeaderViewBannerGrid from '~/components/utils/HeaderViewBannerGrid.vue'

const { t } = useI18n()
const filterStore = useFilterStore()
const { getHeaderChildren } = filterStore
const { lastSelectedHeader, productHeaders, isLoading } = storeToRefs(filterStore)
const { isTeacher } = storeToRefs(useAuthStore())
const { filteredContents, filteredProductPackages } = useContentFilter()
const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())
const { isPackage } = useContentHelper()
const { set: setAppColor } = useAppColor()

const mediaContentId = computed(() => lastSelectedHeader.value?.backgroundMedia && Number(lastSelectedHeader.value.backgroundMedia.destinationContentId))
const { media: bgMedia, isLoading: bgMediaIsLoading } = useMedia(mediaContentId)

const contentItems = computed(() => filteredContents.value.filter((content) => !isPackage(content)))
const isDark = computed(() => lastSelectedHeader.value.colorPair?.isDark)

const childQuery = useQuery({
  queryKey: ['header-view-query', ...filteredProductPackages.value.map(({ locationId }) => locationId)],
  queryFn: () => Promise.all(filteredProductPackages.value.map((content) => getHeaderChildren(content)))
})

const showTeacherButton = computed(() => isTeacher.value
  && hasLoadedProducts.value
  && lastSelectedHeader)

function getColor(key: keyof Omit<ColorTheme, 'isDark'>) {
  const color = lastSelectedHeader.value.colorPair?.[key]
  return color ? colorMap.get(color) : color
}

const primaryColor = computed(() => lastSelectedHeader.value.colorPair?.primary)
const skeletonColor = computed(() => `rgb(var(${getColor('secondary')?.var}))`)
const hasHeaders = computed(() => productHeaders.value.learningPath
  .some(({ locationId }) => locationId === lastSelectedHeader.value.locationId))

const cardStyle = computed(() => ({
  color: `rgb(var(${getColor('text')?.var}))`,
  '--card-bg': `rgb(var(${getColor('primary')?.var}))`,
  '--card-text': `rgb(var(${getColor('text')?.var}))`,
  '--card-border': `rgb(var(${getColor('secondary')?.var}))`,
  '--card-accent': isDark.value
    ? `rgb(var(${getColor('accent')?.var}))`
    : `rgb(var(${getColor('text')?.var}))`,
  '--card-hover': `rgb(var(${getColor('highlight')?.var}))`
}))

const altCardStyle = computed(() => ({
  '--card-bg': `rgb(var(${getColor('accentSecondary')?.var}))`,
  '--card-text': `rgb(var(${getColor('primary')?.var}))`,
  '--card-border': `rgb(var(${getColor('accent')?.var}))`,
  '--card-accent': `rgb(var(${getColor('dark')?.var}))`,
  '--card-hover': `rgb(var(${getColor('accentHighlight')?.var}))`
}))

onMounted(() => primaryColor.value && setAppColor(primaryColor.value))

watch(filteredProductPackages, () => {
  childQuery.refetch()
})
</script>

<template>
  <HeaderViewBannerGrid
    class="py-24"
    :style="cardStyle"
  >
    <h1
      class="sr-only"
      v-text="lastSelectedHeader.title"
    />
    <div
      id="top-dropdowns"
      class="flex gap-2 grid-in-filters"
    >
      <SubjectFilter />
      <GradeFilter />
    </div>
    <div class="flex flex-col items-start gap-4 self-center pr-9 grid-in-header-info">
      <div
        v-if="hasHeaders"
        class="space-y-2"
      >
        <span
          class="text-sm font-bold uppercase tracking-wider"
          v-text="t('filters.chooseTheme')"
        />
        <HeaderFilter
          id="header-filter"
          :index="0"
          full-width
        />
      </div>
      <KsSkeletonWrapper
        v-if="isLoading"
        class="flex w-full flex-col gap-2 text-xl"
      >
        <KsSkeleton
          v-for="n in 5"
          :key="n"
          :width="`${(n % 3)*3+80}%`"
          height="1em"
          :background="skeletonColor"
        />
      </KsSkeletonWrapper>
      <RichTextRenderer
        v-else
        class="prose text-xl text-[currentColor]"
        :text="lastSelectedHeader.intro"
      />
      <div
        :style="{
          '--ks-border': 'rgb(var(--au-yellow-30))',
          '--ks-borderhoverfill': 'rgb(var(--au-yellow-30))',
          'color': isDark ? 'rgb(var(--au-yellow-30))' : 'currentColor',
        }"
      >
        <HeaderTeacherButton
          v-if="showTeacherButton"
          :selected-header="lastSelectedHeader"
          :data-pendo="PendoFeature.TeacherContent.Header"
        />
      </div>
    </div>
    <div class="flex max-w-full items-center self-center justify-self-start grid-in-image md:h-0">
      <Image
        v-if="mediaContentId"
        :content="mediaContentId"
        :background="bgMediaIsLoading && skeletonColor"
        width="1000px"
      />
    </div>
    <CopyrightButton
      v-if="bgMedia && 'copyright' in bgMedia"
      :copyright="bgMedia.copyright"
      class="place-self-end row-start-image col-start-image md:col-span-2 md:translate-y-24 md:col-start-image"
    />
  </HeaderViewBannerGrid>
  <section
    v-if="isLoading"
    class="mx-auto mb-24 flex max-w-screen-au flex-col gap-6"
  >
    <KsSkeleton
      class="font-tiempos text-4xl"
      height="3rem"
      width="12ch"
      :background="skeletonColor"
    />
    <KsSkeletonWrapper class="grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <KsSkeleton
        v-for="n in 8"
        :key="n"
        height="auto"
        class="aspect-video"
        :background="skeletonColor"
      />
    </KsSkeletonWrapper>
  </section>
  <template v-else>
    <div
      class="mx-auto mb-24 flex max-w-screen-au flex-col gap-24 px-4 sm:px-8"
      :style="cardStyle"
    >
      <section
        v-for="(content, index) in filteredProductPackages"
        :key="content.contentId"
        class="flex flex-col gap-4"
      >
        <h2
          class="font-tiempos text-4xl"
          v-text="content.title"
        />
        <ul
          class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
          :class="content.viewMode === 'grid' ? 'gap-6' : 'gap-[1px]'"
        >
          <li
            v-for="childContent in content.children"
            :key="childContent.locationId"
          >
            <GridCard
              v-if="content.viewMode === 'grid'"
              :style="{
                '--card-text': isDark ? `rgb(var(${getColor('dark')?.var}))` : `rgb(var(${getColor('text')?.var}))`,
                '--card-bg': `rgb(var(${getColor('dark')?.var}))`,
                '--card-border': `rgb(var(${getColor('accent')?.var}))`,
              }"
              :resource="childContent"
            />
            <SimpleCard
              v-else
              :resource="childContent"
              :style="Boolean(index % 2) ? null : altCardStyle"
            />
          </li>
        </ul>
      </section>
      <section
        v-if="contentItems.length > 0"
        class="flex flex-col gap-4"
      >
        <h2
          class="font-tiempos text-4xl"
          v-text="t('labels.other')"
        />
        <ul class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <li
            v-for="content in contentItems"
            :key="content.locationId"
          >
            <SimpleCard :resource="content" />
          </li>
        </ul>
      </section>
    </div>
  </template>
</template>

<style scoped>
:deep(.ks-dropdown .ks-dropdown-list ul) {
  max-height: 620px;
  font-size: 1.15rem;
}

#header-filter :deep(input) {
  font-size: 1.3rem;
  border: 1px solid rgb(var(--au-blue-20));
  border-radius: 10px;
}

#header-filter :deep(svg) {
  top: 17px;
}

#header-filter :deep(button) {
  top: 15px;
}

#top-dropdowns :deep(input) {
  font-size: .9rem;
}

#top-dropdowns :deep(.ks-dropdown svg) {
  font-size: .9rem;
}

#top-dropdowns :deep(.ks-dropdown .ks-dropdown-list ul) {
  font-size: .9rem;
}
</style>
