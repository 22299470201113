<script setup lang="ts">
import type { ContentBlogPost } from '~/models/Content/ContentBlogPost'
import { computed } from 'vue'
import { KsIcon } from '@aschehoug/kloss'
import useTime from '~/composables/useTime'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useSlugify from '~/composables/useSlugify'

const props = defineProps<{ blogPost: ContentBlogPost }>()

const { slugify } = useSlugify()
const { dateTimeToAlternativeOutputFormat, isoStringToDateTime } = useTime()
const { buildImageUrlByField } = useUrlBuilder()

const date = computed(() => dateTimeToAlternativeOutputFormat(isoStringToDateTime(props.blogPost.publishedDate)))

const imageUrl = computed(() => {
  const imageId = Number(props.blogPost.image?.destinationContentId)
  return imageId ? buildImageUrlByField(imageId, 'image', 'banner_large') : ''
})

const tag = computed(() => props.blogPost.tags.length ? props.blogPost.tags[0] : '')
const title = computed(() => props.blogPost.title)
const intro = computed(() => props.blogPost.intro)
</script>

<template>
  <li
    v-if="tag"
    class="group relative flex w-full flex-col gap-2"
  >
    <div class="order-1 flex w-full flex-col">
      <h3 class="my-1 text-xl font-bold text-gray-50 underline-offset-2 group-hover:underline">
        <router-link
          :to="{ name: 'singlePost', params: { tag: slugify(tag), locationId: blogPost.locationId, blogPost: slugify(title) } }"
          class="after:absolute after:inset-0 after:z-10 after:rounded-xl after:ring-offset-2 after:focus-visible:ring"
        >
          {{ title }}
        </router-link>
      </h3>
      <div class="order-first inline-flex items-center gap-2">
        <p
          v-if="tag"
          class="text-sm font-medium uppercase tracking-widest text-seagreen-50"
          v-text="tag"
        />
        <span
          v-if="tag"
          aria-hidden="true"
        >&bull;</span>
        <p
          class="text-sm capitalize text-gray-40"
          v-text="date"
        />
      </div>
      <p
        v-if="intro"
        class="line-clamp-2 text-gray-40"
        v-html="intro"
      />
    </div>
    <div class="h-auto w-full rounded-3xl bg-orange-10/30">
      <img
        v-if="imageUrl"
        :src="imageUrl"
        class="block aspect-video h-full w-full rounded-3xl object-cover"
        alt=""
      >
      <div
        v-else
        class="flex h-full w-full items-center justify-center rounded-3xl bg-blue-40 text-white"
      >
        <KsIcon
          id="newspaper"
          :scale="6"
        />
      </div>
    </div>
  </li>
</template>
