import type { AxiosInstance } from 'axios'
import axios from 'axios'
import { getAccessToken } from '~/api/client/oidc'
import { i18n } from '~/translations'
import { toApiCache } from '~/utils/cache'

export const contentClient: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_CONTENT_API_URL,
  headers: {
    'Accept': 'application/json',
  },
  transformRequest: [(data, headers) => {
    // When using the Composition API (which requires "legacy: false" in i18n) the locale
    // variable is a WritableComputedRef, meaning it has "value" property that is not defined
    // here, hence the ts-ignore
    // @ts-ignore
    headers['X-Siteaccess'] = i18n.global.locale.value
    headers['Authorization'] = `Bearer ${getAccessToken()}`
    return JSON.stringify(data)
  }],
})

contentClient.interceptors.response.use((response) => {
  toApiCache(`${response.config.baseURL}${response.config.url}`, response)
  return response
})
