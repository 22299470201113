import { LanguageCode } from '~/models/Content/BaseField'
import { PreferredLanguage } from '~/models/User/PreferredLanguage'

export const reverseMap: Record<LanguageCode, PreferredLanguage> = {
  'nor-NO': PreferredLanguage.Bokmal,
  'nno-NO': PreferredLanguage.Nynorsk
}

export const languageMap: Record<PreferredLanguage, LanguageCode> = {
  [PreferredLanguage.Bokmal]: 'nor-NO',
  [PreferredLanguage.Nynorsk]: 'nno-NO',
}
