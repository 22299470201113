<script setup lang="ts">
import type { AggregationStateEntry, AggregationTermEntry } from '~/models/Content/Response'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { KsButton, KsIcon, KsInput, KsPagination, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import usePendo from '~/composables/usePendo'
import { PendoTrackName } from '~/models/Pendo'
import useProductStore from '~/stores/product'
import { useAuthStore } from '~/stores/auth'
import { sortByGradeIndex } from '~/utils/gradeSorter'
import { sortBySubjectIndex } from '~/utils/subjectSorter'
import arrayUtils from '~/utils/arrayUtils'
import { debounce } from '~/utils/functionUtils'
import { setTitle } from '~/utils/dom'
import useText from '~/composables/useText'
import useSearchLogic from '~/composables/useSearchLogic'
import SearchAnimation from '~/assets/lottie/sok-animation.json'
import CardSkeleton from '~/components/skeletons/CardSkeleton.vue'
import NoSearchResults from '~/components/search/NoSearchResults.vue'
import ActiveFilters from '~/components/search/ActiveFilters.vue'
import SearchSettingsDialog from '~/components/search/SearchSettingsDialog.vue'
import SubjectFilter from '~/components/search/SubjectFilter.vue'
import AggregationFilter from '~/components/search/AggregationFilter.vue'
import CardRenderer from '~/components/cards/CardRenderer.vue'

const PAGE_LIMIT = 24
const DEBOUNCE_MS = 500

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { pendoTrack } = usePendo()
const { unique, intersect, truthy } = arrayUtils()
const { capitalize } = useText()

const { products } = storeToRefs(useProductStore())
const { isLoading: isLoadingProducts, matchingProducts } = storeToRefs(useProductStore())
const { isTeacher, userGrades } = storeToRefs(useAuthStore())

const searchQuery = ref(route.query?.fullText as string || '')
const page = ref(Number(route.query?.page) || 1)

const splitQueryToArray = (name: string): string[] => ((route.query[name] || '') as string)
  .split(',').map((s) => s.trim()).filter(truthy)

const subjectCriterion = ref<string[]>(splitQueryToArray('subject'))
const gradeCriterion = ref<string[]>(splitQueryToArray('grade'))
const labelCriterion = ref<string[]>(splitQueryToArray('label'))
const activityCriterion = ref<string[]>(splitQueryToArray('activity'))
const accessCriterion = ref<string[]>(splitQueryToArray('access'))

const subtreeCriterion = computed(() => matchingProducts.value
  .filter((product) => intersect(product.subjects, subjectCriterion.value).length > 0)
  .map((product) => product.aunivers.pathString as string))

const searchInput = ref<{ el: HTMLElement }>()

const sortedGradeAggregations = computed(() => [...gradeAggregations.value]
  .sort((a, b) => sortByGradeIndex(a.key, b.key)))

const hasQuery = computed(() => searchQuery.value.length > 2)
const returnPath = computed(() => router.currentRoute.value.fullPath)
const hasCriterionsOrQuery = computed(() => hasQuery.value || hasCriterions.value)

const queryParams = computed(() => ({
  ... (hasCriterionsOrQuery.value) ? { fullText: searchQuery.value } : {},
  ... (hasCriterionsOrQuery.value) ? { page: page.value.toString() } : {},
  ... (subjectCriterion.value.length > 0) ? { subject: [...subjectCriterion.value].sort().join(',') } : {},
  ... (gradeCriterion.value.length > 0) ? { grade: [...gradeCriterion.value].sort().join(',') } : {},
  ... (labelCriterion.value.length > 0) ?  { label: [...labelCriterion.value].sort().join(',') } : {},
  ... (activityCriterion.value.length > 0) ?  { activity: [...activityCriterion.value].sort().join(',') } : {},
  ... (accessCriterion.value.length > 0) ? { access: [...accessCriterion.value].sort().join(',') } : {},
}))

const hasCriterions = computed(() => [
  ...labelCriterion.value,
  ...activityCriterion.value,
  ...gradeCriterion.value,
  ...subjectCriterion.value,
  ...accessCriterion.value].length > 0)

const matchingSubjects = computed(() => matchingProducts.value
  .filter(({ grades }) => !userGrades.value.length || intersect(grades, userGrades.value).length > 0)
  .flatMap(({ subjects }) => subjects)
  .filter(unique)
  .sort(sortBySubjectIndex))

const matchingSubtrees = computed(() => matchingProducts.value
  .map(({ aunivers }) => aunivers.pathString as string))

const activeFilters = computed(() => {
  const subjects = subjectCriterion.value
    .map((subject) => t(`metadata.subjects.${subject}`).toLowerCase())
  const grades = gradeCriterion.value
    .map((grade) => t(`metadata.grades.${grade}`).toLowerCase())
  const labels = labelCriterion.value
    .map((label) => t(`labels.${label}`).toLowerCase())
  const activities = activityCriterion.value
    .map((activity) => t(`activities.${activity}`, activity).toLowerCase())
  return [...subjects, ...grades, ...labels, ...activities].join(', ')
})

const placeholderText = computed(() => isLoadingProducts.value
  ? t('search.loadingProducts')
  : activeFilters.value.length
    ? t('search.lookingFor', { filters: activeFilters.value })
    : t('search.placeholder'))

const onInput = debounce(() => {
  replaceRoute()
  if (hasCriterionsOrQuery.value) doSearch()
  isTyping.value = false
}, DEBOUNCE_MS)

function addLabelCriterion(entry: string) {
  const index = labelCriterion.value.findIndex((lb) => lb === entry)
  page.value = 1

  index > -1
    ? labelCriterion.value.splice(index, 1)
    : labelCriterion.value.unshift(entry)
}

function addGradeCriterion(grade: string) {
  const index = gradeCriterion.value.findIndex((g) => g === grade)
  page.value = 1

  index > -1
    ? gradeCriterion.value.splice(index, 1)
    : gradeCriterion.value.unshift(grade)
}

function addActivityCriterion(activity: string) {
  const index = activityCriterion.value.findIndex((a) => a === activity)
  page.value = 1

  index > -1
    ? activityCriterion.value.splice(index, 1)
    : activityCriterion.value.unshift(activity)
}

function addAccessCriterion(access: string) {
  const index = accessCriterion.value.findIndex((a) => a === access)
  page.value = 1

  index > -1
    ? accessCriterion.value.splice(index, 1)
    : accessCriterion.value.unshift(access)
}

function addSubjectCriterion(subject: string) {
  page.value = 1
  subjectCriterion.value.includes(subject)
    ? subjectCriterion.value = subjectCriterion.value.filter((s) => s !== subject)
    : subjectCriterion.value.push(subject)
}

function resetLabelCriterion() {
  labelCriterion.value = []
}

function resetActivityCriterion() {
  activityCriterion.value = []
}

function resetAccessCriterion() {
  accessCriterion.value = []
}

function resetCriterions() {
  page.value = 1
  subjectCriterion.value = []
  labelCriterion.value = []
  activityCriterion.value = []
  gradeCriterion.value = []
  accessCriterion.value = []
}

function resetSearch() {
  searchQuery.value = ''
  resetCriterions()
  resetResults()
  router.push({ name: 'search' })
  searchInput.value?.el.focus()
}

function submitForm() {
  replaceRoute()
}

function pushPage(pageNumber: number) {
  page.value = pageNumber
  replaceRoute()
  doSearch()
}

function replaceRoute() {
  router.replace({ name: 'search', query: queryParams.value })
}

const setIsTyping = () => isTyping.value = true

const {
  doSearch,
  items,
  isLoading,
  totalPages,
  totalCount,
  isTyping,
  noResults,
  labelAggregations,
  activityAggregations,
  gradeAggregations,
  accessAggregations,
  hasFailed,
  resetResults
} = useSearchLogic(searchQuery, page, PAGE_LIMIT,() => ({
  labelFieldCriterion: labelCriterion.value,
  activityFieldCriterion: activityCriterion.value,
  subtreeCriterion: subtreeCriterion.value.length > 0
    ? subtreeCriterion.value
    : matchingSubtrees.value,
  gradeFieldCriterion: gradeCriterion.value,
  accessStateCriterion: accessCriterion.value,
}), pendoTrack, PendoTrackName.SearchPage, {
  subjects: queryParams.value.subject || '',
  grades: queryParams.value.grade || '',
  labels: queryParams.value.label || '',
})

watch([
  subjectCriterion,
  gradeCriterion,
  labelCriterion,
  activityCriterion,
  accessCriterion,
], () => {
  if (hasCriterionsOrQuery.value) {
    replaceRoute()
    doSearch()
  }
  replaceRoute()
}, { deep: true })

watch(hasCriterionsOrQuery,() => {
  if (!hasCriterionsOrQuery.value) resetResults()
})

onMounted(() => {
  setTitle(t('search.title'))
  if (hasCriterionsOrQuery.value) doSearch()
  setTimeout(() => {
    if (searchInput.value) searchInput.value.el.focus()
  }, 250)
})
</script>

<template>
  <section class="mx-auto mb-10 mt-4">
    <div class="mx-auto max-w-3xl px-4 sm:px-8">
      <h1
        class="mb-4 text-center text-3xl font-bold text-gray-50"
        v-text="t('search.globalSearch')"
      />
      <form
        role="search"
        class="relative rounded-full"
        @submit.prevent="submitForm"
      >
        <KsIcon
          id="magnifying-glass"
          class="absolute left-7 top-1/2 z-10 -translate-y-1/2 text-xl"
        />
        <KsInput
          ref="searchInput"
          v-model="searchQuery"
          type="text"
          maxlength="200"
          name="search"
          :aria-label="t('search.title')"
          shape="rounded"
          class="!pl-16 !text-xl placeholder:text-lg placeholder:text-gray-50"
          :disabled="isLoadingProducts"
          :placeholder="placeholderText"
          @input="onInput(); setIsTyping();"
        />
      </form>
    </div>
  </section>

  <div
    v-if="!hasCriterions && !hasQuery"
    class="mx-auto max-w-screen-au px-4 sm:px-8"
  >
    <div class="flex flex-col items-center justify-center gap-4">
      <h2
        class="text-lg font-medium"
        v-text="t('search.filters.filterBySubject')"
      />
      <ul class="flex max-w-2xl flex-wrap justify-center gap-3">
        <li
          v-for="(subject, i) in matchingSubjects"
          :key="i"
        >
          <KsButton
            variant="secondary"
            shape="rounded"
            @click="addSubjectCriterion(subject)"
          >
            {{ t(`metadata.subjects.${subject}`) }}
          </KsButton>
        </li>
      </ul>
    </div>
    <LottieAnimation
      :animation-data="SearchAnimation"
      width="80%"
      :aria-label="t('search.animationLabel')"
    />
  </div>

  <div
    v-if="hasCriterionsOrQuery"
    class="mx-auto flex max-w-screen-2xl flex-col gap-8 px-4 sm:px-8 md:grid md:grid-cols-12 xl:gap-10"
  >
    <div class="col-span-full space-y-8 lg:col-span-3 xl:col-span-3">
      <SubjectFilter
        v-if="hasCriterions || hasQuery"
        :is-loading="isLoading"
        :subjects="matchingSubjects"
        :selected-subjects="subjectCriterion"
        @add-criterion="addSubjectCriterion"
      />

      <AggregationFilter
        v-if="gradeAggregations.length"
        :title="t('filters.grade')"
        :is-loading="isLoading"
        :aggregations="sortedGradeAggregations"
        :criterions="gradeCriterion"
        :translator="(entry: AggregationTermEntry) => t(`metadata.grades.${entry.key}`)"
        @add-criterion="addGradeCriterion"
      />

      <AggregationFilter
        v-if="labelAggregations.length"
        :title="t('search.contentTypes')"
        :is-loading="isLoading"
        :aggregations="labelAggregations"
        :criterions="labelCriterion"
        :translator="(entry: AggregationTermEntry) => t(`labels.${entry.key}`)"
        @add-criterion="addLabelCriterion"
      />

      <AggregationFilter
        v-if="activityAggregations.length"
        :title="t('activities.messages.title')"
        :is-loading="isLoading"
        :aggregations="activityAggregations"
        :criterions="activityCriterion"
        :translator="(entry: AggregationTermEntry) => t(`activities.${entry.key}`, capitalize(entry.key))"
        @add-criterion="addActivityCriterion"
      />

      <AggregationFilter
        v-if="isTeacher && accessAggregations.length"
        :title="t('access.title')"
        :is-loading="isLoading"
        :aggregations="accessAggregations"
        :criterions="accessCriterion"
        :translator="(entry: AggregationStateEntry) => t(`access.${entry.key}`)"
        @add-criterion="addAccessCriterion"
      />
    </div>

    <div class="col-span-full lg:col-span-9 xl:col-span-9">
      <div class="mb-5 flex items-center justify-between">
        <div class="flex flex-wrap items-center gap-3">
          <p
            v-if="!isLoading && hasCriterionsOrQuery && !isTyping"
            class="mr-4 flex flex-col text-gray-50"
          >
            <span
              class="text-xl font-bold"
              v-text="t('search.resultsCount', { count: totalCount })"
            />
            <span
              v-if="!noResults"
              class="text-xs"
              v-text="t('search.currentPage', { page: page, totalPages: totalPages > 0 ? totalPages : page })"
            />
          </p>
          <KsSkeletonWrapper
            v-else
            class="mr-4 flex flex-col gap-1"
          >
            <KsSkeleton
              width="80px"
              height="44px"
            />
          </KsSkeletonWrapper>
          <ActiveFilters
            v-if="hasCriterions"
            :grade-criterion="gradeCriterion"
            :label-criterion="labelCriterion"
            :activity-criterion="activityCriterion"
            :access-criterion="accessCriterion"
            :selected-subjects="subjectCriterion"
            @reset-label-criterion="resetLabelCriterion"
            @reset-activity-criterion="resetActivityCriterion"
            @add-grade-criterion="addGradeCriterion"
            @add-subject-criterion="addSubjectCriterion"
            @reset-access-criterion="resetAccessCriterion"
            @reset-criterions="resetCriterions"
          />
        </div>
        <SearchSettingsDialog
          v-if="hasCriterionsOrQuery"
          :products="matchingProducts"
        />
      </div>

      <ul
        v-if="!isLoading"
        class="grid grid-cols-1 gap-6 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"
      >
        <CardRenderer
          v-for="item in items"
          :key="`location-${item.locationId}`"
          :resource="item"
          :access-control="true"
          :return-path="returnPath"
          :products="products"
        />
      </ul>
      <ul
        v-if="isLoading"
        class="grid grid-cols-1 gap-6 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"
      >
        <CardSkeleton
          v-for="i in PAGE_LIMIT"
          :key="i"
        />
      </ul>
      <NoSearchResults
        v-if="!isTyping"
        :no-results="noResults"
        :has-query="hasQuery"
        :has-failed="hasFailed"
        :is-loading="isLoading"
        :has-criterions="hasCriterions"
        @reset-search="resetSearch"
        @reset-criterions="resetCriterions"
      />
      <KsPagination
        v-if="totalCount > PAGE_LIMIT && !isLoading"
        class="mx-auto mt-16 flex justify-end gap-4"
        :current-page="page"
        :items-per-page="PAGE_LIMIT"
        :item-count="totalCount"
        @push-page="pushPage"
      />
    </div>
  </div>
</template>
