<script setup lang="ts">

import type { TeacherArticle, TeacherArticleContent } from '~/models/TeacherArticle'
import type { BaseItem } from '~/models/Content/BaseItem'
import type { RouteLocationRaw } from 'vue-router'
import { ContentType } from '~/models/Content/ContentType'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import useContentApi from '~/api/contentApi'
import { useContentHelper } from '~/composables/useContentHelper'
import useArrayUtils from '~/utils/arrayUtils'
import { useI18n } from 'vue-i18n'
import useSlugify from '~/composables/useSlugify'
import { waitFor } from '~/utils/asyncUtils'
import useBreakpoint from '~/composables/useBreakpoint'
import { useScrollBehavior } from '~/composables/useScrollBehavior'
import { toCombinedString } from '~/utils/gradeSorter'
import TeacherSubitems from '~/components/subject/TeacherSubitems.vue'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import CloseButton from '~/components/utils/CloseButton.vue'

useScrollBehavior()

const props = defineProps<{
  locations: TeacherArticle[]
  closeOverride?: RouteLocationRaw
}>()

const menu = ref<HTMLElement>()

const { t } = useI18n()
const { findContents } = useContentApi()
const { getTitle, findSubjectFromContent, packageTypes, isPackage, isArticleContent } = useContentHelper()
const { truthy } = useArrayUtils()
const { slugify } = useSlugify()
const { getBreakpoint } = useBreakpoint()

const article = computed(() => props.locations[0])
const articleRelations = computed(() => article.value?.contents?.destinationContentIds ?? [])

const articleGrades = computed(() => {
  if (!article.value) return ''
  return toCombinedString(article.value.grades)
})

const articleSubject = computed(() => {
  if (!article.value) return ''
  const subject = findSubjectFromContent(article.value)
  if (!subject) return ''
  return t(`metadata.subjects.${subject}`)
})

const menuItems = computed(() => {
  if (!article.value) return []
  return [
    article.value,
    ...( articleContents.value ? articleContents.value: [])
  ].map((articleContent) => {
    const title = getTitle(articleContent)
    return {
      title,
      id: slugify(title),
    }
  })
})

const {
  data: articleContents,
  refetch: refetchArticleContents,
} = useQuery({
  enabled: articleRelations.value.length > 0,
  staleTime: Infinity,
  queryKey: ['teacher-subject-content', props.locations.map(({ locationId }) => locationId).join(',')],
  queryFn: async () => {
    const contents = await findContents<BaseItem|TeacherArticleContent>({
      contentIdCriterion: articleRelations.value,
      contentTypeCriterion: [...[ContentType.ArticleContent], ...packageTypes],
      mainLocationCriterion: true,
    })
    return articleRelations.value
      .map((destinationContentId: number|string) => contents.find(({ contentId }) => contentId === Number(destinationContentId)))
      .filter(truthy<BaseItem|TeacherArticleContent>)
  }
})

const setScrollMargin = () => {
  if (!menu.value) return
  const menuHeight = getComputedStyle(menu.value).height
  document.querySelectorAll('header, article').forEach((el: Element) => {
    if (!(el instanceof HTMLElement)) return
    if (getBreakpoint() > 2) el.style.scrollMarginTop = '0px'
    else el.style.scrollMarginTop = menuHeight
  })
}

onMounted(async () => {
  refetchArticleContents()
  await waitFor(() => !!menu.value, 1000)
  window.addEventListener('resize', setScrollMargin)
  setScrollMargin()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', setScrollMargin)
})
</script>
<template>
  <div
    v-if="article"
    class="flex min-h-full flex-col text-blue-50 md:grid md:grid-cols-12"
  >
    <CloseButton
      :item="article"
      :override="closeOverride"
      is-fixed
      :style="{
        '--ks-border': 'rgb(var(--au-blue-50))',
        '--ks-borderhoverfill': 'rgb(var(--au-orange-20))',
      }"
    />
    <ul
      ref="menu"
      class="sticky left-0 top-0 col-span-4 flex h-fit w-full flex-wrap gap-2 bg-orange-10 p-5 pr-16 md:left-4 md:top-16 md:w-fit md:flex-col md:gap-4 md:bg-transparent md:p-0 lg:col-span-3 xl:left-10"
    >
      <li
        v-for="menuItem in menuItems"
        :key="menuItem.id"
        class=""
      >
        <a
          class="inline-block rounded-full bg-[#FFF6EE] px-3 py-2 font-inter text-sm font-medium transition-all hover:bg-orange-30/40 focus-visible:ring md:px-5 xl:text-base"
          :href="`#${menuItem.id}`"
          v-text="menuItem.title"
        />
      </li>
    </ul>
    <div class="col-span-8 lg:col-span-9">
      <header
        :id="slugify(getTitle(article))"
        class="au-teacher-article bg-orange-5 p-5 fluid-text-lg md:mx-5 md:mt-16 md:p-8"
      >
        <div class="divide-x text-base">
          <span
            v-if="articleGrades"
            class="pr-2"
            v-text="articleGrades"
          />
          <span
            v-if="articleSubject"
            class="px-2"
            v-text="articleSubject"
          />
          <span
            class="pl-2"
            v-text="t(`contentTypes.guide`)"
          />
        </div>
        <h1
          class="mb-4 mt-8 font-extrabold fluid-text-4xl"
          v-text="getTitle(article)"
        />
        <RichTextRenderer :text="article.intro" />
      </header>
      <article
        v-for="content in articleContents"
        :id="slugify(getTitle(content))"
        :key="content.contentId"
        class="au-teacher-article px-5 py-1 last:mb-16"
      >
        <h2 v-text="getTitle(content)" />
        <TeacherSubitems
          v-if="isPackage(content)"
          :header="content"
        />
        <RichTextRenderer
          v-if="isArticleContent(content)"
          :text="content.body"
        />
      </article>
    </div>
  </div>
</template>
