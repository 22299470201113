<script setup lang="ts">
import type { FiltersQueryParams } from '~/models/Filter'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import SubjectFilter from '~/components/ResourceFinder/SubjectFilter.vue'
import GradeFilter from '~/components/ResourceFinder/GradeFilter.vue'
import HeaderFilter from '~/components/ResourceFinder/HeaderFilter.vue'
import ProductFilter from '~/components/ResourceFinder/ProductFilter.vue'
import TaskTypeFilter from '~/components/ResourceFinder/TaskTypeFilter.vue'
import ColophonButton from '~/components/ResourceFinder/ColophonButton.vue'
import useFilterStore from '~/stores/filter'
import useQueryParams from '~/composables/queryParams'
import useFilterSettingsStore from '~/stores/filterSettings'
import { QueryParamKey } from '~/models/QueryParamKeys'
import useProductStore from '~/stores/product'

const props = withDefaults(defineProps<{
  autoSelect?: boolean
  enableSubjectFilter?: boolean
  enableGradeFilter?: boolean
}>(), {
  autoSelect: false,
  enableSubjectFilter: true,
  enableGradeFilter: true,
})

const { t } = useI18n()
const filterStore = useFilterStore()
const {
  selectedHeaderChildren,
  selectedHeaders,
} = storeToRefs(filterStore)
const { resetViewMode } = filterStore
const { filterOptions } = storeToRefs(useFilterSettingsStore())
const { clear } = useQueryParams<FiltersQueryParams>(QueryParamKey.Filters)
const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())

const clearFilter = () => {
  resetViewMode()
  clear()
}
</script>

<template>
  <header
    id="resource-finder"
    class="relative mx-auto my-8 flex max-w-screen-au flex-col flex-wrap gap-4 px-4 transition-all sm:flex-row sm:items-center sm:px-8"
  >
    <SubjectFilter
      v-if="enableSubjectFilter"
      v-show="filterOptions.subject"
    />
    <GradeFilter
      v-if="enableGradeFilter"
      v-show="filterOptions.grade"
    />
    <ProductFilter v-show="filterOptions.product" />
    <HeaderFilter
      v-show="filterOptions.header"
      :index="0"
      :enable-grade-filter="enableGradeFilter"
    />
    <HeaderFilter
      v-for="(headerChildren, index) in selectedHeaderChildren"
      v-show="filterOptions.header"
      :key="headerChildren[0]?.locationId"
      :index="index + 1"
      :resources="headerChildren"
      :auto-select="props.autoSelect"
      :enable-grade-filter="enableGradeFilter"
    />
    <TaskTypeFilter v-show="filterOptions.taskType" />
    <KsButton
      v-if="selectedHeaders.length > 0"
      variant="tertiary"
      shape="rounded"
      @click="clearFilter"
    >
      {{ t('filters.emptyFilter') }}
    </KsButton>
    <ColophonButton v-if="hasLoadedProducts" />
  </header>
</template>
