<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useSectionStore } from '~/stores/section'
import { useAuthStore } from '~/stores/auth'
import SectionsSkeleton from '~/components/skeletons/SectionsSkeleton.vue'
import BlogPostSection from '~/components/blog/BlogPostSection.vue'
import SectionRenderer from '~/components/ResourceEngine/SectionRenderer.vue'

const sectionStore = useSectionStore()
const { isTeacher } = storeToRefs(useAuthStore())
const { isLoading, userSections } = storeToRefs(sectionStore)
const { loadSections } = sectionStore

loadSections(import.meta.env.VITE_SECTION_PARENT_ID)
</script>

<template>
  <section
    v-if="isLoading"
    class="mx-auto max-w-screen-au"
  >
    <SectionsSkeleton />
  </section>

  <BlogPostSection
    v-if="isTeacher"
    :limit="3"
  />

  <SectionRenderer
    v-if="!isLoading && userSections.length"
    :items="userSections"
  />
</template>
