import type { AxiosInstance } from 'axios'
import axios from 'axios'
import { getAccessToken } from '~/api/client/oidc'
import { i18n } from '~/translations'

export const restClient: AxiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_AUNIVERS_SITEURL}${import.meta.env.VITE_BASE_API_PATH}`,
  headers: {
    'Content-Type': import.meta.env.VITE_HEADER_CONTENT_TYPE,
    Accept: import.meta.env.VITE_HEADER_ACCEPT,
  },
  transformRequest: [(data, headers) => {
    // When using the Composition API (which requires "legacy: false" in i18n) the locale
    // variable is a WritableComputedRef, meaning it has "value" property that is not defined
    // here, hence the ts-ignore
    // @ts-ignore-next-line
    headers['X-Siteaccess'] = i18n.global.locale.value
    headers['Authorization'] = `Bearer ${getAccessToken()}`
    return JSON.stringify(data)
  }],
})

// Added { _href } for ease of use, since most ibexa objects comes with one
export const expandEndpoint = (data: string | { _href: string }) => {
  return restClient
    .get((typeof data === 'string' ? data : data._href).replace(import.meta.env.VITE_BASE_API_PATH, ''))
    .then((response) => response.data)
}
