import type { Plan } from '~/models/Planner/Plan'
import type { Week } from '~/models/Planner/Week'
import type { Interval } from 'luxon'
import { DateTime } from 'luxon'
import { useSchoolYear } from '~/composables/useSchoolYear'
import useArrayUtils from '~/utils/arrayUtils'

export function useTime() {
  const currentWeekNumber: number = DateTime.local().weekNumber

  const getPreviousWeekNumbers = (count: number): number[] => {
    const weekNumbers: number[] = []
    for(let i = 0; i < count; i++) {
      weekNumbers.push(currentWeekNumber - i)
    }

    return weekNumbers
  }

  const getWeeksBackToSchoolYearStart = (): number[] => {
    const { currentSchoolYearInterval } = useSchoolYear()
    const weekNumbers = getWeekNumbersInInterval(currentSchoolYearInterval)
    return weekNumbers.slice(0, weekNumbers.indexOf(currentWeekNumber)+1)
  }

  const getWeekNumbersInInterval = (interval: Interval): number[] => {
    const { unique, truthy } = useArrayUtils()
    const days = interval.splitBy({ day: 1 })
    if (days.length === 0) return []
    return days.flatMap(({ start: day }) => day?.weekNumber).filter(truthy<number>).filter(unique)
  }

  const getWeekNumbersInCurrentSchoolYear = (): number[] => {
    const { currentSchoolYearInterval } = useSchoolYear()
    return getWeekNumbersInInterval(currentSchoolYearInterval)
  }

  const getFirstWeekNumberOfPlan = (plan: Plan): number => {
    const firstWeekNumber = getWeekNumbersInCurrentSchoolYear().find((weekNumber) => {
      return plan.weeks.find((week) => week.weekNumber === weekNumber)
    })

    if (firstWeekNumber === undefined) {
        return 0
    }

    return firstWeekNumber
  }

  const getLastWeekNumberOfPlan = (plan: Plan): number => {
    const lastWeekNumber = getWeekNumbersInCurrentSchoolYear().reverse().find((weekNumber) => {
      return plan.weeks.find((week) => week.weekNumber === weekNumber)
    })

    if (lastWeekNumber === undefined) {
      return 0
    }

    return lastWeekNumber
  }

  const weekNumberToDateTime = (weekNumber: number, year?: number): DateTime => {
    return DateTime.fromObject({
      weekYear: year ?? DateTime.local().weekYear,
      weekNumber: weekNumber,
      weekday: 1,
      second: 1,
    })
  }

  const weekToDateTime = (week: Week): DateTime => {
    return DateTime.fromObject({
      weekNumber: week.weekNumber,
      weekYear: week.weekYear,
    })
  }

  const isoStringToDateTime = (isoString: string): DateTime => {
    return DateTime.fromISO(isoString)
  }

  const dateTimeToStandardOutputFormat = (dateTime: DateTime): string => {
    return dateTime.toFormat('dd.MM.yyyy')
  }

  const dateTimeToAlternativeOutputFormat = (dateTime: DateTime): string => {
    return dateTime.toFormat('dd LLL yyyy')
  }

  const availableStartWeeks = (plans: Plan[], selectedEndWeek?: number, currentPlan: null|Plan = null): number[] => {
    const freedWeeks: number[] = []
    if (currentPlan !== null) {
      freedWeeks.push(...getWeeksInUse([currentPlan]))
    }

    const allWeeks = getWeekNumbersInCurrentSchoolYear()

    const reservedWeeks = getWeeksInUse(plans).filter( (item) => { return freedWeeks.indexOf(item) < 0 })

    let weekNumbers = allWeeks.filter((x) => {
      return reservedWeeks.indexOf(x) < 0
    })

    if (typeof selectedEndWeek === 'undefined') {
      return weekNumbers
    }

    const endWeekIndex = weekNumbers.indexOf(selectedEndWeek)
    if(endWeekIndex >= 0) {
      weekNumbers = weekNumbers.slice(0, endWeekIndex + 1)
    }

    weekNumbers = weekNumbers
      .filter((weekNumber) => {
        const allWeeksStartIndex = allWeeks.indexOf(weekNumber)
        const allWeeksEndIndex = allWeeks.indexOf(<number>selectedEndWeek)
        const weekNumbersStartIndex = weekNumbers.indexOf(weekNumber)
        const weekNumbersEndIndex = weekNumbers.indexOf(<number>selectedEndWeek)

        const actualPeriodLength = allWeeks.slice(allWeeksStartIndex, allWeeksEndIndex).length
        const potentialPeriodLength = weekNumbers.slice(weekNumbersStartIndex, weekNumbersEndIndex).length

        return actualPeriodLength === potentialPeriodLength
      })

    return weekNumbers
  }

  const availableEndWeeks = (plans: Plan[], selectedStartWeek: undefined|number, currentPlan: null|Plan = null): number[] => {
    const freedWeeks: number[] = []
    if (currentPlan !== null) {
      freedWeeks.push(...getWeeksInUse([currentPlan]))
    }

    const allWeeks = getWeekNumbersInCurrentSchoolYear()

    const reservedWeeks = getWeeksInUse(plans).filter( (item) => { return freedWeeks.indexOf(item) < 0 })

    let weekNumbers = allWeeks.filter((x) => {
      return reservedWeeks.indexOf(x) < 0
    })

    if (typeof selectedStartWeek === 'undefined') {
      return weekNumbers
    }

    const startWeekIndex = weekNumbers.indexOf(selectedStartWeek)

    if(startWeekIndex >= 0) {
      weekNumbers = weekNumbers.slice(startWeekIndex)
    }

    weekNumbers = weekNumbers
      .filter((weekNumber) => {
        const allWeeksStartIndex = allWeeks.indexOf(<number>selectedStartWeek)
        const allWeeksEndIndex = allWeeks.indexOf(weekNumber)
        const weekNumbersStartIndex = weekNumbers.indexOf(<number>selectedStartWeek)
        const weekNumbersEndIndex = weekNumbers.indexOf(weekNumber)

        const actualPeriodLength = allWeeks.slice(allWeeksStartIndex, allWeeksEndIndex).length
        const potentialPeriodLength = weekNumbers.slice(weekNumbersStartIndex, weekNumbersEndIndex).length

        return actualPeriodLength === potentialPeriodLength
      })

    return weekNumbers
  }

  const getWeeksInUse = (plans: Plan[]) => {
    return plans.flatMap(plan => plan.weeks).map(week => week.weekNumber)
  }

  const getWeekNumbersFromPlan = (plan: Plan) => {
    const weekNumbers = []
    for (const week of plan.weeks) {
      weekNumbers.push(week.weekNumber)
    }

    const sortByWeekInSchoolYear = (a: number, b: number):
      number => getWeekNumbersInCurrentSchoolYear().indexOf(a ?? 1) - getWeekNumbersInCurrentSchoolYear().indexOf(b ?? 1)
    weekNumbers.sort(sortByWeekInSchoolYear)

    if(weekNumbers.length === 0) {
      return
    }

    if(weekNumbers.length === 1) {
      return weekNumbers[0] + ' '
    }

    return `${weekNumbers[0]} - ${weekNumbers[weekNumbers.length - 1]}`
  }

  const currentWeekDate = weekToDateTime({
    weekNumber: currentWeekNumber,
    weekYear: DateTime.local().weekYear,
  } as Week)

  return {
    currentWeekNumber,
    currentWeekDate,
    getWeekNumbersInInterval,
    getPreviousWeekNumbers,
    getWeeksBackToSchoolYearStart,
    getWeekNumbersInCurrentSchoolYear,
    getFirstWeekNumberOfPlan,
    getLastWeekNumberOfPlan,
    weekNumberToDateTime,
    isoStringToDateTime,
    dateTimeToStandardOutputFormat,
    dateTimeToAlternativeOutputFormat,
    weekToDateTime,
    availableEndWeeks,
    availableStartWeeks,
    getWeekNumbersFromPlan,
  }
}

export default useTime
