<script setup lang="ts">
import type { ContentResource } from '~/models/Content/ContentResource'
import { KsButton } from '@aschehoug/kloss'
import { computed, onBeforeMount, ref } from 'vue'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { useRouter } from 'vue-router'
import { waitFor } from '~/utils/asyncUtils'

const { props, resources } = defineProps<{
  resources: ContentResource[]
  props: ContentResource
  featured: []
}>()

const { buildResourceUrl } = useUrlBuilder()
const router = useRouter()

const displayTitle = computed(() => props.displayTitle || props.title)

const resource = computed(() => resources[0])

const url = computed(() => resource.value ? buildResourceUrl(resource.value) : '')

function openUrl() {
  if (url.value.includes(import.meta.env.VITE_HOST) || url.value.startsWith('/')) {
    router.push(url.value.replace(import.meta.env.VITE_HOST, ''))
    return
  }
  if (!document || !document.location) return
  document.location.href = url.value
}

const hasTeleportTarget = ref(false)

function checkTeleportTarget() {
  if (!document || !document.querySelector) return
  hasTeleportTarget.value = Boolean(document.querySelector(TeleportationTarget.SubjectRowButtons))
}

onBeforeMount(async () => {
  const teleportTargetCheckInterval = setInterval(checkTeleportTarget, 100)
  await waitFor(() => hasTeleportTarget.value, 5000)
  clearInterval(teleportTargetCheckInterval)
})

</script>

<template>
  <Teleport
    v-if="hasTeleportTarget"
    :to="TeleportationTarget.SubjectRowButtons"
  >
    <KsButton
      variant="secondary"
      shape="rounded"
      class="bg-white hover:bg-seagreen-20 sm:text-base"
      :href="url"
      @click.prevent="openUrl"
    >
      {{ displayTitle }}
    </KsButton>
  </Teleport>
</template>
