<script setup lang="ts">
import type { Product } from '~/models/Product'
import type { SubjectCode } from '~/models/Subject'
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsInput } from '@aschehoug/kloss'
import arrayUtils from '~/utils/arrayUtils'
import { useAuthStore } from '~/stores/auth'
import useProductStore from '~/stores/product'
import { isUpperSecondaryProduct, sortByNameGradeSubject } from '~/utils/productSorter'
import { sortBySubjectIndex } from '~/utils/subjectSorter'
import DefaultGreetings from '~/components/home/DefaultGreetings.vue'
import DefaultIllustration from '~/components/home/DefaultIllustration.vue'

const subjectGroup: { [key: string]: Array<SubjectCode> } = {
  'NOR': ['NOR'],
  'MAT': ['MAT'],
  'LANG': ['ENG', 'SPA', 'TYS', 'FRA', 'FSP' ],
  'NAT': ['NAT', 'GFG', 'KJE', 'INF', 'FYS'],
  'SAF': ['SAF', 'HIS', 'SAK', 'SOS', 'SAG', 'SOK', 'POM', 'HEL', 'PSY', 'REL', 'KRL', 'GEO'],
  'VOK': ['VOK'],
}

const { t } = useI18n()
const router = useRouter()
const { intersect, unique } = arrayUtils()
const authStore = useAuthStore()
const { userSubjectsByGrades, userGrades } = storeToRefs(authStore)
const { products } = storeToRefs(useProductStore())

const selectedSubjectGroup = ref<string>()

const allProducts = computed(() => products.value.filter(isUpperSecondaryProduct))
const filteredSubjects = computed(() => selectedSubjectGroup.value ? (subjectGroup[selectedSubjectGroup.value] ?? []) : userSubjectsByGrades.value)

const productSubjects = computed(() => allProducts.value
  .flatMap(({ subjects }) => subjects).filter(unique).sort(sortBySubjectIndex))

const filteredGroups = computed(() => Object.keys(subjectGroup)
  .filter((key) => intersect(subjectGroup[key], productSubjects.value).length > 0))

const hasUserGrades = (product: Product) => !product.grades.length || intersect(product.grades, userGrades.value).length > 0
const hasUserSubjects = (product: Product) => !product.subjects.length || intersect(product.subjects, filteredSubjects.value).length > 0
const hasUserSettings = (product: Product) => hasUserGrades(product) && hasUserSubjects(product)

const filteredProducts = computed(() => allProducts.value
  .filter((product) => hasUserSettings(product))
  .sort(sortByNameGradeSubject))

const setSubjectGroup = (group: string) => {
  localStorage.setItem('subject-group', group)
  selectedSubjectGroup.value = group
}

const buildLegacyProductUrl = (product: Product) => {
  if (!product.aunivers || !product.aunivers.urlAlias) {
    return router.resolve({ name: 'notfound' }).href
  }
  const url = new URL(product.aunivers.urlAlias)
  return `${import.meta.env.VITE_AUNIVERS_SITEURL}${url.pathname}`
}

onMounted(() => {
  const cachedGroup = localStorage.getItem('subject-group')
  selectedSubjectGroup.value = cachedGroup ?? undefined
})

watch(userGrades, () => {
  localStorage.removeItem('subject-group')
  selectedSubjectGroup.value = undefined
})
</script>
<template>
  <header class="relative mb-12 flex-col justify-center bg-gradient-to-tr from-seagreen-40 to-custom-green to-60% text-white">
    <div class="relative z-20 mx-auto h-full w-full max-w-screen-au px-4 pb-10 pt-40 transition-all xs:pb-14 sm:px-8 sm:pt-28">
      <h1
        class="sr-only"
        v-text="t('home.title')"
      />
      <DefaultGreetings />
      <p
        v-if="filteredProducts.length"
        class="mb-2 mt-4 font-medium"
        v-text="t('home.yourProducts')"
      />
      <div
        v-if="filteredProducts.length"
        class=" xs:pb-0 md:max-w-[70%]"
      >
        <div
          v-if="filteredGroups.length"
          class="md-2 my-6 items-center bg-green-20 p-6 lg:flex"
        >
          <div
            v-for="groupCode in filteredGroups"
            :key="`group-${groupCode}`"
          >
            <KsButton @click="setSubjectGroup(groupCode)">
              <KsInput
                v-model="selectedSubjectGroup"
                type="radio"
                name="subjectGroup"
                :checked="groupCode === selectedSubjectGroup"
                :value="groupCode"
              />
              {{ t(`metadata.subjects.${groupCode}`) }}
            </KsButton>
          </div>
        </div>
        <ul
          v-if="filteredProducts.length"
          class="relative z-10 -mx-4 flex h-fit flex-wrap gap-3 overflow-x-visible px-4 pb-4"
        >
          <li
            v-for="product in filteredProducts"
            :key="product.ean"
          >
            <KsButton
              variant="secondary"
              shape="rounded"
              class="!bg-white hover:!bg-seagreen-20 sm:text-base"
              :href="buildLegacyProductUrl(product)"
            >
              {{ product.aunivers.name }}
            </KsButton>
          </li>
        </ul>
        <DefaultIllustration />
      </div>
    </div>
  </header>
</template>
