import { Color, ColorName, type ColorTheme } from '~/models/Content/Color'
import type { ColorPair } from '~/models/Presentation/BasePage'
import { colorMap } from '~/utils/colors'

function createColorPair(text: ColorName, bg: ColorName, isDark: boolean): ColorPair {
  const [bgFallback, textFallback] = colorMap.values()
  return {
    text: colorMap.get(text) ?? textFallback,
    background: colorMap.get(bg) ?? bgFallback,
    isDark,
  }
}

const colorPairs: Record<string, ColorPair> = {
  'Gerd': createColorPair(ColorName.GreenPop, ColorName.Green50, true),
  'Gerd 2': createColorPair(ColorName.Green50, ColorName.GreenPop, false),
  'Gina': createColorPair(ColorName.Coral20, ColorName.Green50, true),
  'Gina 2': createColorPair(ColorName.Green50, ColorName.Coral20, false),
  'Gitte': createColorPair(ColorName.YellowPop, ColorName.Green30, true),
  'Gitte 2': createColorPair(ColorName.Green30, ColorName.YellowPop, false),
  'Gøril': createColorPair(ColorName.SeaGreen10, ColorName.SeaGreen50, true),
  'Gøril 2': createColorPair(ColorName.SeaGreen50, ColorName.SeaGreen10, false),
  'Ronald': createColorPair(ColorName.CoralPop, ColorName.Coral60, true),
  'Ronald 2': createColorPair(ColorName.Coral60, ColorName.CoralPop, false),
  'Ruben': createColorPair(ColorName.Coral20, ColorName.Blue50, true),
  'Ruben 2': createColorPair(ColorName.Blue50, ColorName.Coral20, false),
  'Rasmus': createColorPair(ColorName.Yellow30, ColorName.Gray50, true),
  'Rasmus 2': createColorPair(ColorName.Gray50, ColorName.Yellow30, false),
}

export default () => {
  const getBackgroundColor = (colorName: string) => colorName ? colorName : 'blue-10'

  const getColorPair = (colorPairName: string) => colorPairName ? colorPairs[colorPairName] : colorPairs['Gerd']

  function getColorTheme(themeCode: string): ColorTheme {
    const [primary = 'blue', accent = 'orange', shade = 'dark'] = themeCode.split(' ').filter((x) => x)
    const isDark = shade === 'dark'

    return isDark
      ? <ColorTheme>{
        text: 'white',
        primary: `${primary}-50`,
        secondary: `${primary}-pop`,
        highlight: `${primary}-60`,
        dark: `${primary}-60`,
        accent: `${primary}-pop`,
        accentSecondary: `${primary}-5`,
        accentHighlight: `${primary}-10`,
        isDark,
      }
      : <ColorTheme>{
        text: `${primary}-60`,
        primary: `${primary}-10`,
        secondary: `${primary}-50`,
        highlight: `${primary}-5`,
        dark: `${primary}-5`,
        accent: `${primary}-pop`,
        accentSecondary: `${primary}-50`,
        accentHighlight: `${primary}-60`,
        isDark,
      }
  }

  return {
    getColorPair,
    getColorTheme,
    getBackgroundColor
  }
}
