<script setup lang="ts">
import type { Group, GroupMember } from '~/models/Group'
import { GroupRole, GroupSource, GroupType } from '~/models/Group'
import type { GradeCode } from '~/models/Grade'
import type { SubjectCode } from '~/models/Subject'
import { UserRole } from '~/models/User/UserRole'
import { computed, inject, onMounted, ref } from 'vue'
import { KsButton, KsDropdown, KsInput, KsSpinner } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { findGradesByGradeType, sortByGradeIndex } from '~/utils/gradeSorter'
import { useAuthStore } from '~/stores/auth'
import useGroupsStore from '~/stores/groups'
import SearchMembers from '~/components/settings/SettingsGroups/SearchMembers.vue'
import { sortBySubjectIndex, subjectsSorted } from '~/utils/subjectSorter'
import { usePlannerApi } from '~/api/plannerApi'
import useArrayUtils from '~/utils/arrayUtils'

const props = defineProps<{
  group: Group
  groupMembers: GroupMember[]
}>()

const emit = defineEmits(['changeView', 'back', 'groupCreated'])

const { t } = useI18n()
const ksToast = <Toast>inject('ksToast')

let initialGroupMembers: GroupMember[] = []

const groupName = ref('')
const groupGrade = ref<Nullable<GradeCode>>(null)
const groupSubject = ref<Nullable<SubjectCode>>(null)
const newGroupMembers = ref<GroupMember[]>([])
const newGroup = ref(false)

const groupsStore = useGroupsStore()
const { createGroup, sortMembers } = groupsStore
const { isLoading, selectedGroup } = storeToRefs(groupsStore)
const authStore = useAuthStore()
const { setUserActiveGroup } = authStore
const { userSubjectsByGrades, activeUserGroup, user, username, selectedGradeType, userReadOnlyGrades } = storeToRefs(authStore)
const { unique } = useArrayUtils()
const { updateYearPlansByGroupId } = usePlannerApi()

const grades = computed(() => [
  ...(props.group.grade ? [props.group.grade] : []),
  ...(selectedGradeType.value ? findGradesByGradeType(selectedGradeType.value) : []),
  ...userReadOnlyGrades.value,
].filter(unique).sort(sortByGradeIndex))
const subjects = computed(() => [
  ...(props.group.subjects?.length ? props.group.subjects : []),
  ...(userSubjectsByGrades.value.length ? userSubjectsByGrades.value : subjectsSorted),
].filter(unique).sort(sortBySubjectIndex))
const isFeide = computed(() => props.group?.groupSource === GroupSource.Feide)

const readyToSave = computed(() => {
  if (!newGroup.value) {
    return props.group.name !== groupName.value
      || props.group.grade !== groupGrade.value
      || props.group.subjects[0] !== groupSubject.value
      || newGroupMembers.value !== initialGroupMembers
      || (props.group && !props.group.groupId)
  }
  return groupName.value && newGroupMembers.value.length > 0
})

const currentUserInMembers = computed(() => {
  return Boolean([...newGroupMembers.value, ...props.groupMembers]
    .find(({ userId }) => userId === username.value))
})

async function populateGroup() {
  const group = { ... props.group }
  group.name = groupName.value
  group.grade = groupGrade.value || null
  group.subjects = groupSubject.value ? [groupSubject.value] : []
  group.groupSource = group.groupSource || GroupSource.Local
  group.groupType = group.groupType || GroupType.Adhoc
  try {
    if (!currentUserInMembers.value) {
      newGroupMembers.value = [...newGroupMembers.value, {
        userId: username.value,
        firstName: user.value?.userData.firstName || '',
        lastName: user.value?.userData.lastName || '',
        fullName: user.value?.userData.fullName || '',
        role: user.value?.userData.role || UserRole.Student,
        groupRole: GroupRole.Admin,
      }]
    }
    const [newGroup] = await createGroup(group, newGroupMembers.value)

    // Updates the active group if it's the same group
    if (activeUserGroup.value && activeUserGroup.value.groupId === newGroup.groupId) {
      selectedGroup.value = newGroup
      await setUserActiveGroup(newGroup)
    }

    // Update yearplans if editing existing group
    if (props.group?.groupId) {
      updateYearPlansByGroupId(newGroup.groupId)
    }

    emit('groupCreated', newGroup)
    ksToast.success(t('groups.saveSuccess'))
  } catch (error) {
    ksToast.error(t('groups.saveError'))
    throw error
  } finally {
    emit('changeView')
  }
}

const isMemberInGroup = (newMember: GroupMember): boolean =>
  !!newGroupMembers.value.find((member) => member.userId === newMember.userId)

const selectMember = (member: GroupMember) => {
  if (!isMemberInGroup(member)) {
    const groupRole = member.role === UserRole.Teacher ? GroupRole.Admin : GroupRole.Member
    newGroupMembers.value = [...newGroupMembers.value, { ...member, groupRole }]
  }
}

const newGroupMembersSorted = computed(() => {
  const teachers = newGroupMembers.value.filter(({ role }) => role === UserRole.Teacher).sort(sortMembers)
  const students = newGroupMembers.value.filter(({ role }) => role !== UserRole.Teacher).sort(sortMembers)
  return [teachers, students].flatMap((member) => member)
})

const removeGroupMember = (userId: string) => {
  newGroupMembers.value = newGroupMembers.value.filter(member => member.userId !== userId)
}

onMounted(() => {
  if (!Object.keys(props.group).length) {
    newGroup.value = true
    return
  }
  groupGrade.value = props.group.grade || null // need feedback on this
  groupSubject.value = props.group.subjects.length ? props.group.subjects[0] : null
  groupName.value = props.group.name
  initialGroupMembers = props.groupMembers
  newGroupMembers.value = props.groupMembers
})

</script>

<template>
  <div class="flex relative animate-fade h-full flex-col gap-6 border-t border-gray-10 py-6">
    <label class="flex flex-col gap-1">
      <span
        class="font-medium"
        v-text="t('groups.groupName')"
      />
      <KsInput
        v-model="groupName"
        type="text"
        :placeholder="t('groups.groupNamePlaceholder')"
        shape="rounded"
        :disabled="isFeide"
      />
    </label>
    <label class="flex flex-col gap-1">
      <span
        class="font-medium"
        v-text="t('groups.grade')"
      />
      <KsDropdown
        v-model="groupGrade"
        :options="grades"
        :placeholder="t('filters.chooseGrade')"
        shape="rounded"
        :no-options="t('groups.noGrades')"
        :close-on-select="true"
        :option-label="(opt: string) => t(`metadata.grades.${opt}`)"
      />
    </label>
    <label class="flex flex-col gap-1">
      <span
        class="font-medium"
        v-text="t('filters.subject')"
      />
      <KsDropdown
        v-model="groupSubject"
        :options="subjects"
        :placeholder="t('filters.chooseSubject')"
        shape="rounded"
        :no-options="t('groups.noSubjects')"
        :close-on-select="true"
        :option-label="(opt: string) => t(`metadata.subjects.${opt}`)"
      />
    </label>
    <div>
      <p
        class="font-medium"
        v-text="t('groups.members')"
      />
      <SearchMembers
        v-if="!isFeide"
        @select-member="selectMember"
      />
      <ul
        v-if="newGroupMembersSorted.length"
        class="mb-2"
      >
        <li
          v-for="member in newGroupMembersSorted"
          :key="`${props.group.groupId}-${member.userId}`"
          class="flex animate-fade items-center justify-between border-b border-gray-10 py-2 last:border-0"
        >
          <p class="flex items-center gap-3">
            {{ member.fullName }}
            <span
              v-if="member.groupRole === GroupRole.Admin"
              class="rounded-lg bg-blue-30 px-2 py-1 text-xs font-bold uppercase tracking-widest text-white"
              v-text="t('groups.admin')"
            />
          </p>
          <KsButton
            v-if="!isFeide && member.userId !== user?.username"
            :aria-label="t('groups.removeMember')"
            size="small"
            shape="round"
            icon-left="xmark"
            @click="removeGroupMember(member.userId)"
          />
        </li>
      </ul>
    </div>
    <div class="sticky bottom-0 py-4 px-6 w-full right-0 bg-white border-t border-gray-30 flex items-center justify-end gap-4">
      <KsSpinner
        v-if="isLoading"
        size="small"
      />
      <KsButton
        variant="secondary"
        shape="rounded"
        @click="newGroup ? emit('back') : emit('changeView')"
      >
        {{ t('groups.cancel') }}
      </KsButton>
      <KsButton
        id="saveBtn"
        variant="primary"
        shape="rounded"
        :disabled="!readyToSave || isLoading"
        @click="populateGroup"
      >
        {{ t('groups.saveGroup') }}
      </KsButton>
    </div>
  </div>
</template>
