import type { Aggregation, AggregationStateEntry, AggregationTermEntry } from '~/models/Content/Response'
import arrayUtils from '~/utils/arrayUtils'

export default () => {
  const { uniqueBy } = arrayUtils()

  /**
   * A "term" aggregates search by the value of the search field
   *
   * @param name
   * @param aggregations
   */
  const mapTermAggregations = (name: string, aggregations: Aggregation[]): AggregationTermEntry[] => {
    const entries = aggregations
    .filter((aggregation) => aggregation.name === name)
    .flatMap((aggregate: Aggregation) => aggregate.entries as AggregationTermEntry[])
    // Sum count for identical labels
    return entries.map((aggregation) => ({
      ...aggregation,
      key: aggregation.key as string,
      count: entries.reduce((acc, agg) => {
        acc += agg.key === aggregation.key ? agg.count : 0
        return acc
      }, 0)
    }))
    .filter(uniqueBy('key'))
    .sort((a, b) => b.count - a.count)
  }

  /**
   * A "state" aggregates an Ibexa object state value
   *
   * @param name
   * @param aggregations
   */
  const mapStateAggregations = (name: string, aggregations: Aggregation[]): AggregationTermEntry[] => aggregations
    .filter((aggregation) => aggregation.name === name)
    .flatMap((aggregate: Aggregation) => aggregate.entries as AggregationStateEntry[])
    .map((entry) => (({
      ...entry,
      key: entry.key.ObjectState.identifier
    })))

  return {
    mapStateAggregations,
    mapTermAggregations,
  }
}
