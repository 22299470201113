import { DateTime } from 'luxon'

interface Snapshot {
  url: string
  scrollPosition: number
  pageHeight: number
  timestamp: number
}

const SNAPSHOT_LOCALSTORAGE_KEY = 'scroll-snapshots'
/**
 * This composable is used to save and restore scroll positions when navigating between pages.
 *
 * This is done by adding an event listener to the scroll event, which saves a snapshot, described
 * by the Snapshot interface, to a Map indexed by the URL **without** query parameters. We also save
 * the height of the page at the time of the snapshot, so that we can check if the page has changed
 * size since the snapshot was taken. If it has, we need to wait for the page to finish loading. If
 * the length of the page is the same as in the snapshot, we scroll to the stored position.
 */
export default () => {
  let snapshots = new Map<string, Snapshot>()
  let disableSnapshots = false

  function snapshot() {
    if (disableSnapshots) return
    disableSnapshots = true

    const snapshot: Snapshot = {
      url: window.location.pathname,
      scrollPosition: window.scrollY,
      pageHeight: document.body.scrollHeight,
      timestamp: DateTime.now().toMillis(),
    }

    snapshots.set(window.location.pathname, snapshot)
    cacheSnapshots()
    disableSnapshots = false
  }

  function cacheSnapshots() {
    const snapshotsJson = JSON.stringify(Array.from(snapshots.entries()))
    localStorage.setItem(SNAPSHOT_LOCALSTORAGE_KEY, snapshotsJson)
  }

  async function restoreSnapshots() {
    disableSnapshots = true

    const snapshotsJson = localStorage.getItem(SNAPSHOT_LOCALSTORAGE_KEY)
    if (snapshotsJson) {
      snapshots = new Map(JSON.parse(snapshotsJson))
    }

    // Perhaps we need to change the time for this? 8 hours could be both too long and too short.
    const timeout = DateTime.now().plus({ hours: 8 }).toMillis()
    for (const [url, snapshot] of snapshots.entries()) {
      if (timeout < snapshot.timestamp) {
        snapshots.delete(url)
      }
    }

    await scrollToSnapshot(window.location.pathname)
    disableSnapshots = false
  }

  function stop() {
    window.removeEventListener('scroll', snapshot)
  }

  function start() {
    window.addEventListener('scroll', snapshot)
    restoreSnapshots()
  }

  /** How many attempts should we make to scroll to the appropriate position? */
  let attempts = 0
  const maxAttempts = 50

  async function scrollToSnapshot(url: string): Promise<void> {
    const snapshot = snapshots.get(url)
    if (snapshot) {
      // check if the page is long enough to scroll to the Snapshot
      const { scrollHeight: currentPageHeight } = document.body
      if (currentPageHeight === snapshot.pageHeight) {
        window.scrollTo(0, snapshot.scrollPosition)
      } else {
        if (attempts > maxAttempts) {
          console.debug(`Could not scroll to snapshot for ${url} after ${maxAttempts} attempts. Snapshot will probably not make sense next time. Deleting it.`)
          snapshots.delete(url)
          return
        }
        return new Promise((resolve) => {
          attempts++
          setTimeout(() => resolve(scrollToSnapshot(url)), 100)
        })
      }
    }
    snapshots.delete(url)
  }

  return {
    start,
    stop,
  }
}
