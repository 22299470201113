<script setup lang="ts">
import { KsButton } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const { t } = useI18n()

const webshopUrl = computed(() => <string>import.meta.env.VITE_WEBSHOP_TRIALURL)
const supportUrl = computed(() => <string>import.meta.env.VITE_WEBSHOP_SUPPORT)
</script>
<template>
  <footer class="z-10 m-auto w-full max-w-screen-au p-5">
    <ul class="flex flex-col flex-wrap justify-center gap-2 text-sm xs:flex-row">
      <li>
        <KsButton
          icon-left="headphones"
          variant="tertiary"
          class="text-sm sm:text-base"
          :href="supportUrl"
          target="_blank"
        >
          {{ t('footer.customerService') }}
        </KsButton>
      </li>
      <li>
        <KsButton
          icon-left="lamp"
          variant="tertiary"
          class="text-sm sm:text-base"
          href="https://innhold.aunivers.no/eksamen"
          target="_blank"
        >
          {{ t('footer.exam') }}
        </KsButton>
      </li>
      <li>
        <KsButton
          icon-left="lock"
          variant="tertiary"
          class="text-sm sm:text-base"
          stretch
          href="https://innhold.aunivers.no/personvern"
          target="_blank"
        >
          {{ t('footer.privacy') }}
        </KsButton>
      </li>
      <li>
        <KsButton
          icon-left="universal-access"
          variant="tertiary"
          class="text-sm sm:text-base"
          href="https://innhold.aunivers.no/uu"
          target="_blank"
        >
          {{ t('footer.accessibility') }}
        </KsButton>
      </li>
      <li>
        <KsButton
          icon-left="bag"
          variant="tertiary"
          class="text-sm sm:text-base"
          :href="webshopUrl"
          target="_blank"
        >
          {{ t('footer.webshop') }}
        </KsButton>
      </li>
      <li>
        <KsButton
          icon-left="copyright"
          variant="tertiary"
          class="text-sm sm:text-base"
          href="https://innhold.aunivers.no/rettigheter"
          target="_blank"
        >
          {{ t('footer.copyright') }}
        </KsButton>
      </li>
      <li>
        <KsButton
          icon-left="gingerbread-man"
          variant="tertiary"
          class="text-sm sm:text-base"
          href="https://innhold.aunivers.no/cookies"
          target="_blank"
        >
          {{ t('footer.cookies') }}
        </KsButton>
      </li>
    </ul>
    <aside class="mt-5 text-sm text-gray-40 xs:text-center">
      {{ `Aschehoug &copy; ${ new Date().getFullYear() }` }}
    </aside>
  </footer>
</template>
