import { readonly, ref } from 'vue'
import { rdfRestClient } from '~/api/client/rdfRestClient'
import { Caption, CaptionsKind } from '~/models/Caption'
import { rewriteMediaURL } from '~/utils/media'

const path = new Map<CaptionsKind, string>([
  ['subtitles', 'captions'],
  ['chapters', 'chapters'],
])

export function useCaptions(kind: CaptionsKind = 'subtitles') {
  const _captions = ref<Caption[]>()
  const _isLoading = ref()

  const captions = readonly(_captions)
  const isLoading = readonly(_isLoading)

  async function fetchCaptions(contentItemId: string) {
    _isLoading.value = true

    try {
      const { data } = await rdfRestClient.get(`/resources/contentItems/${contentItemId}/${path.get(kind)}`)

      _captions.value = data
        .results
        .bindings
        .map((binding: any): Caption => ({
          url: rewriteMediaURL((binding.captionFile || binding.chapterFile).value),
          language: binding.ISOlanguageCode.value,
          label: binding.languageLabel.value,
        }))
    }

    finally {
      _isLoading.value = false
    }
  }

  return {
    captions,
    isLoading,
    fetchCaptions,
  }
}
