<script setup lang="ts">
import { GradeType } from '~/models/GradeType'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import LowerPrimaryHeader from '~/components/subject/gradetypes/LowerPrimaryHeader.vue'
import LowerSecondaryHeader from '~/components/subject/gradetypes/LowerSecondaryHeader.vue'

const { selectedGradeType } = storeToRefs(useAuthStore())
</script>

<template>
  <LowerPrimaryHeader v-if="selectedGradeType === GradeType.LowerPrimary" />
  <LowerSecondaryHeader v-if="selectedGradeType === GradeType.LowerSecondary" />
</template>
