<script setup lang="ts">
import type { Presentation } from '~/models/Presentation/Presentation'
import type { PresentationPage } from '~/models/Presentation/BasePage'
import { PendoFeature } from '~/models/Pendo'
import { computed } from 'vue'
import { KsButton } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import NavigationPopover from '~/components/PresentationPages/Components/NavigationPopover.vue'

const props = defineProps<{
  pageNumber: number
  pages: PresentationPage[]
  presentation: Presentation
}>()

const emit = defineEmits(['nextPage', 'prevPage'])

const { t } = useI18n()

const totalPages = computed(() => props.pages.length)
const multiplePages = computed(() => props.pages.length > 1)
const canGoToNextPage = computed(() => props.pageNumber < totalPages.value)
const canGoToPreviousPage = computed(() => props.pageNumber > 1)
const currentPage = computed(() => props.pages[props.pageNumber - 1])
const colorPair = computed(() => currentPage.value.colorPair)
const teacherGuideId = computed(() => currentPage.value.teacherGuide?.destinationContentId
  || props.presentation.teacherGuide?.destinationContentId)
</script>

<template>
  <div class="pointer-events-none absolute inset-x-0 bottom-0 flex flex-col">
    <div class="pointer-events-auto flex items-center gap-4 self-end p-4">
      <NavigationPopover
        v-if="multiplePages || teacherGuideId"
        :presentation="presentation"
        :page-number="pageNumber"
        :pages="pages"
      />
      <p
        v-if="multiplePages"
        class="rounded-lg px-2 py-1 text-lg font-medium"
        :style="{ color: colorPair.text.rgb, background: colorPair.background.rgb }"
        v-text="t('presentation.pageNumberOfTotal', { pageNumber, totalPages })"
      />
      <KsButton
        v-if="multiplePages"
        :disabled="!canGoToPreviousPage"
        shape="round"
        icon-left="arrow-left"
        size="large"
        :aria-label="t('search.paginator.prevPage')"
        :style="{ background: colorPair.background.rgb, color: colorPair.text.rgb, border: `1.5px solid ${colorPair.text.rgb}` }"
        :data-pendo="PendoFeature.Presentation.Previous"
        @click="emit('prevPage')"
      />
      <KsButton
        v-if="multiplePages"
        :disabled="!canGoToNextPage"
        shape="round"
        icon-left="arrow-right"
        size="large"
        :aria-label="t('search.paginator.nextPage')"
        :style="{ background: colorPair.text.rgb, color: colorPair.background.rgb, border: `1.5px solid ${colorPair.background.rgb}` }"
        :data-pendo="PendoFeature.Presentation.Next"
        @click="emit('nextPage')"
      />
    </div>
    <div
      id="audio-embed-outlet"
      class="pointer-events-auto [&:not(:empty)]:p-4"
      :style="{ backgroundColor: `rgb(var(${colorPair.text.var}) / .95)`, color: colorPair.background.rgb }"
    />
  </div>
</template>

<style scoped>
:deep(.active) {
  background: v-bind('colorPair.background.rgb');
  color: v-bind('colorPair.text.rgb')
}
:deep(.ks-popover-content) {
  padding: 0!important;
  border: 1px solid v-bind('colorPair.background.rgb');
}
</style>
