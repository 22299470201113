import { computed } from 'vue'
import { LoginSource } from '~/models/Login'
import { useRouter } from 'vue-router'

export default () => {
  const router = useRouter()

  const defaultRedirectUri = computed(() => router.currentRoute.value.query?.redirectUri
    ? String(router.currentRoute.value.query.redirectUri)
    : `https://${import.meta.env.VITE_HOST}`)

  const createLoginUrl = (source: LoginSource, redirectUri?: string) =>
    `${source}?redirect_uri=${redirectUri ?? defaultRedirectUri.value}`

  return {
    createLoginUrl
  }
}
