import type { ImageRelationField, ImageSize, ImageVariations, RelationField } from '~/models/Content/BaseField'
import type { ContentImage } from '~/models/Content/ContentImage'
import type { ContentSvg } from '~/models/Content/ContentSvg'
import type { Image } from '~/models/Content/Image'
import type { Ref } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useContentApi from '~/api/contentApi'

export default () => {
  const { buildImageUrlByField, buildSvgUrlByField } = useUrlBuilder()
  const { findImageContent, findSvgContent } = useContentApi()

  const getImageSrc = (imageField: ImageRelationField, variation: ImageVariations = 'banner_large'): string => {
    return imageField.uri
      || buildImageUrlByField(Number(imageField.destinationContentId), 'image', variation)
      || ''
  }

  const getSvgSrc = (svgField: RelationField): string =>
    buildSvgUrlByField(Number(svgField.destinationContentId))

  const getImageSizeClass = (imageSize: ImageSize) => ({
    none: 'object-cover',
    contain: 'object-contain',
    cover: 'object-cover',
  })[imageSize]

  const getImageAltText = (imageField: ImageRelationField): string => imageField.alternativeText || ''

  const mapImage = (
    image: ContentImage|undefined,
    imageField: ImageRelationField,
    variation: ImageVariations = 'banner_large'
  ): Image|undefined => {
    if (!image || !imageField) return
    return {
      altText: image.image.alternativeText || '',
      caption: image.caption || '',
      shortCaption: image.shortCaption || '',
      copyright: image.copyright || '',
      src: getImageSrc(imageField, variation) || '',
      palette: image.palette || '',
      magnifier: image.magnifier || false,
    }
  }

  const mapSvg = (
    svg: ContentSvg|undefined,
    svgField: RelationField
  ): Image|undefined => {
    if (!svg || !svgField) return
    return {
      altText: svg.alternativeText || '',
      caption: svg.caption || '',
      copyright: svg.copyright || '',
      src: getSvgSrc(svgField) || '',
      magnifier: svg.magnifier || false,
    }
  }

  const useImageQuery = (
    imageField: ImageRelationField,
    variation: ImageVariations = 'banner_large'
  ) => {
    const { data } = useQuery({
      queryKey: ['img', imageField.destinationContentId],
      queryFn: () => findImageContent(Number(imageField.destinationContentId)),
      select: (data: ContentImage|undefined) => mapImage(data, imageField, variation),
      enabled: imageField.destinationContentId !== undefined,
      staleTime: Infinity
    })
    return data
  }

  const useSvgQuery = (svgField: RelationField) => {
    const { data } = useQuery({
      queryKey: ['svg', svgField.destinationContentId],
      queryFn: () => findSvgContent(Number(svgField.destinationContentId)),
      select: (data: ContentSvg|undefined) => mapSvg(data, svgField),
      enabled: svgField.destinationContentId !== undefined,
      staleTime: Infinity
    })
    return data
  }

  const getImage = (
    img: ImageRelationField,
    variation: ImageVariations = 'banner_large'
  ) => useImageQuery(img, variation)

  const getSvg = (svg: RelationField) => useSvgQuery(svg)

  const getSvgOrImage = (
    svg: RelationField,
    img: ImageRelationField,
    variation: ImageVariations = 'banner_large'
  ): Ref<Image|undefined>|undefined => {
    if (svg.destinationContentId) return useSvgQuery(svg)
    if (img.destinationContentId) return useImageQuery(img, variation)
    return
  }

  return {
    getImageSrc,
    getImageAltText,
    getImageSizeClass,
    getSvgSrc,
    useImageQuery,
    useSvgQuery,
    getImage,
    getSvg,
    getSvgOrImage
  }
}
