import type { Product } from '~/models/Product'
import { LicenseModel, ProductVariation } from '~/models/Product'

export function useSearchProduct() {
  const ALWAYS_SEARCHABLE_PRODUCTS: Product[] = [
    {
      'ean': '9788203410833',
      'name': 'Biblioteket',
      'site': 'AUNIVERS',
      'productType': 'COMMON',
      'grades': [
        'aarstrinn8',
        'aarstrinn9',
        'aarstrinn6',
        'aarstrinn7',
        'aarstrinn4',
        'aarstrinn5',
        'aarstrinn2',
        'aarstrinn3',
        'aarstrinn1',
        'aarstrinn10'
      ],
      'subjects': [
        'NOR',
        'ENG'
      ],
      'aunivers': {
        'locationId': 160137,
        'thumbnailUrl': '/images/_aliases/thumbnail_account/5/5/3/5/4645355-1-nor-NO/Biblioteket.png',
        'thumbnailColor': '#BDD8DF',
        'inDevelopment': false,
        'disabled': false,
        'remoteId': 'f02694f596326b180184d99d18e1fb49',
        'design': 'center',
        'externalUrl': null,
        'name': 'Biblioteket',
        'thumbnailAlt': 'Biblioteket.png',
        'redirectLocation': null,
        'promoted': true,
        'relatedLocations': {
          'interdisciplinaryContentLocationId': 0,
          'teacherContentLocationId': 0,
          'learningPathsLocationId': 160162,
          'flexibleContentLocationId': 160140,
          'colophonLocationId': 256910
        },
        'urlAlias': `${import.meta.env.VITE_AUNIVERS_SITEURL}/fagpakker/tverrfaglige-ressurser/biblioteket/`,
        'productVariation': ProductVariation.Addon,
        'pathString': '/1/2/160/2581/160137/',
        'webshopUrl': import.meta.env.VITE_WEBSHOP_TRIALURL,
      },
      'licenseModel': LicenseModel.School
    },
    {
      'ean': '9788203412554',
      'name': 'Papir+',
      'site': 'AUNIVERS',
      'productType': 'COMMON',
      'grades': [
        'aarstrinn6',
        'aarstrinn7',
        'aarstrinn4',
        'aarstrinn5',
        'aarstrinn2',
        'aarstrinn3',
        'aarstrinn1'
      ],
      'subjects': [
        'NOR',
        'MAT',
        'ENG'
      ],
      'aunivers': {
        'locationId': 251197,
        'thumbnailUrl': '/images/_aliases/thumbnail_account/5/5/8/5/6335855-1-nor-NO/a5bda4a17ba0-PapirPluss_Logo_Hvit.png',
        'thumbnailColor': null,
        'inDevelopment': false,
        'disabled': false,
        'remoteId': '1035d9401e471d7d8375ae9b924f3932',
        'design': 'center',
        'externalUrl': null,
        'name': 'Papir+',
        'thumbnailAlt': null,
        'redirectLocation': null,
        'promoted': true,
        'relatedLocations': {
          'interdisciplinaryContentLocationId': 0,
          'teacherContentLocationId': 0,
          'learningPathsLocationId': 251199,
          'flexibleContentLocationId': 251202,
          'colophonLocationId': 0
        },
        'urlAlias': `${import.meta.env.VITE_AUNIVERS_SITEURL}/fagpakker/tverrfaglige-ressurser/papir/`,
        'productVariation': ProductVariation.Addon,
        'pathString': '/1/2/160/2581/251197/',
        'webshopUrl': import.meta.env.VITE_WEBSHOP_TRIALURL,
      },
      'licenseModel': LicenseModel.School
    },
    {
      'ean': '9788203412783',
      'name': 'Terminprøver',
      'site': 'AUNIVERS',
      'productType': 'COMMON',
      'grades': [
        'aarstrinn8',
        'aarstrinn9',
        'aarstrinn10'
      ],
      'subjects': [
        'NOR',
        'MAT',
        'ENG'
      ],
      'aunivers': {
        'locationId': 276164,
        'thumbnailUrl': '/images/_aliases/thumbnail_account/3/4/8/1/7271843-7-nor-NO/348732ce424e-terminproever-thumb.png',
        'thumbnailColor': null,
        'inDevelopment': false,
        'disabled': false,
        'remoteId': '490b79029640bd0d51c17c658e98808c',
        'design': 'center',
        'externalUrl': null,
        'name': 'Terminprøver',
        'thumbnailAlt': null,
        'redirectLocation': null,
        'promoted': true,
        'relatedLocations': {
          'interdisciplinaryContentLocationId': 0,
          'teacherContentLocationId': 0,
          'learningPathsLocationId': 276165,
          'flexibleContentLocationId': 0,
          'colophonLocationId': 0
        },
        'urlAlias': `${import.meta.env.VITE_AUNIVERS_SITEURL}/fagpakker/tverrfaglige-ressurser/terminproever/`,
        'productVariation': ProductVariation.Addon,
        'pathString': '/1/2/160/2581/276164/',
        'webshopUrl': import.meta.env.VITE_WEBSHOP_TRIALURL,
      },
      'licenseModel': LicenseModel.School
    },
  ]

  return {
    ALWAYS_SEARCHABLE_PRODUCTS
  }
}
