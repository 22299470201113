import { useRoute, useRouter } from 'vue-router'

interface QueryParams<T> extends Record<string, string | string[] | boolean | boolean[] | null | Partial<T>> {}

export default <T>(key: string, routeUrl?: string)=> {
  const route = useRoute()
  const router = useRouter()

  const isSemanticValue = (value: object | string | boolean | Array<any> | null): boolean => {
    if (value === null) {
      return false
    }
    if (typeof value === 'string' || typeof value === 'boolean') {
      return true
    }
    if (Array.isArray(value)) {
      return value.length !== 0 && value.every((v) => isSemanticValue(v))
    }
    return typeof value === 'object'
  }

  const buildQueryParams = (input: QueryParams<T>) => {
    return Object.keys(input)
      .filter((k) => isSemanticValue(input[k]))
      .map((key) => `${key}=${JSON.stringify(input[key])}`)
      .join('&')
  }

  const getParams = (): QueryParams<T> => {
    const params: QueryParams<T> = {}
    Object.keys(route?.query ?? {}).forEach((key) => {
      try {
        params[key as string] = JSON.parse((route.query[key] as string) || '{}')
      } catch (err) {
      }
    })
    return params
  }

  const buildUrl = (input: QueryParams<T>): string =>
    `${routeUrl ? routeUrl : router.currentRoute.value.path}?${buildQueryParams(input)}`

  /**
   * Set the queryParams and navigate to the new url.
   *
   * @param input QueryParams to set
   * @param options Options for navigation:
   *   updateHistory: Boolean. Should the browser history be updated by this `setParams` call. The
   *     default option is `true`, as the history should usually be updated, but in cases where we
   *     update the queryParams automatically (e.g. when the user selects a filterOption, or we set
   *     the current week automatically in PlannedResources), we don't want to update the history.
   */
  const setParams = (input: QueryParams<T>, options: Record<string, boolean> = { updateHistory: true }) => {
    const { updateHistory } = options
    if (updateHistory) return router.push(buildUrl(input))
    return router.replace(buildUrl(input))
  }

  const updateParams = async (input: Record<string, Partial<T> | null>, options: Record<string, boolean> = {}) => {
    await setParams({
      ...getParams(),
      ...input,
    }, options)
  }

  const set = async (input: Partial<T>, options: Record<string, boolean> = {}) => {
    return await updateParams({ [key]: input }, options)
  }

  const get = (): Partial<T> => (getParams()[key] || {}) as Partial<T>

  const clear = async (options: Record<string, boolean> = {}) => await updateParams({ [key]: null }, options)

  const getUrl = (input: Partial<T>): string => buildUrl({ [key]: input })

  return {
    set,
    get,
    clear,
    getUrl,
  }
}
