<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import type { ContentPackage } from '~/models/Content/ContentPackage'
import type { ContentProductContainer } from '~/models/Content/ContentProductContainer'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import type { ContentProductPackage } from '~/models/Content/ContentProductPackage'
import { computed, ref } from 'vue'
import { useUrlBuilder } from '~/composables/useUrlBuilder'

type HasImage = ContentProductPackage|ContentProductHeader|ContentProductContainer

const props = defineProps<{ package: BaseItem|ContentPackage }>()
const imageField = ref('image')

const { buildImageUrlByField } = useUrlBuilder()
const imageUrl = computed(() => (props.package as HasImage)?.image?.destinationContentId
  ? buildImageUrlByField(Number(props.package.image.destinationContentId), imageField.value)
  : '')

const imageAlt = computed(() => (props.package as HasImage)?.image?.alternativeText ?? '')
</script>

<template>
  <section class="mx-auto my-8 grid h-full grid-cols-1 items-center gap-12 md:grid-cols-2">
    <img
      v-if="imageUrl"
      :src="imageUrl"
      class="max-h-96 w-full rounded-2xl object-cover"
      :alt="imageAlt"
      @error="imageField = 'thumbnail'"
    >
    <article class="au-cms">
      <h2
        class="mt-0"
        v-text="package.title"
      />
      <div v-html="package.intro" />
    </article>
  </section>
</template>
